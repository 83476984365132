import { TSHourlySensorCount } from "../../@types/TSHourlySensorCount";

export const createTSHourlySensorCountObjects = (hourRecordCount: number): TSHourlySensorCount[] => {
    const objs: TSHourlySensorCount[] = [];
    for (let i = 0; i < 24; i++) {
        const hourString = `${i < 10 ? '0' : ''}${i}:00`;
        const hourObject: TSHourlySensorCount = {
            hour: i,
            hourString: hourString,
            count: null,
            hourRecordCount: hourRecordCount
        };
        objs.push(hourObject);
    }
    return objs;
}
