/*
 * findEntityById
 *
*/
import { DataMode } from "../../@types/DataMode";

export const findEntityById = (dataMode: DataMode, array: any[], id: string) => {

    let foundEntity = null;

    if (array) {
        array.some(obj => {
            if ((dataMode === DataMode.LIVE && obj.id === id) || (dataMode === DataMode.TIME_SERIES && obj.bID === id)) {
                foundEntity = obj;
                return true; // Break out of the loop if the object is found
            }
            if (obj.children && Array.isArray(obj.children)) {
                foundEntity = findEntityById(dataMode, obj.children, id); // Recursively search children arrays
                return foundEntity !== null; // Break out of the loop if object is found in children
            }
            return null
        });
    }

    return foundEntity;
}
