/*
 * SumCumulativeCount
 * 
*/
import { timeSeriesRangeQuery } from "../../api/timeseries/timeSeriesRangeQuery"

export const getSumCumulativeCount = async (
    timeSeriesURL: string,
    startDateTime: string,
    endDateTime: string,
    step: string = "10m",
    organisation: string,
    digitalTwinEntity: string): Promise<string> => {

    let value = 0

    if (startDateTime && endDateTime && step && digitalTwinEntity) {
        const query = `sum by(digitalTwinEntity, metric) (sum_over_time({metric='totalCheckins', organisation='${organisation}', digitalTwinEntity='${digitalTwinEntity}'}))`
        const sumCumulativeCount = await timeSeriesRangeQuery(timeSeriesURL, query, startDateTime, endDateTime, '1h')

        sumCumulativeCount.forEach((result: any) => {
            result['values'].forEach((v: any) => {
                value = value + parseInt(v[1])
            })
        })
    }

    return `${value}`
}