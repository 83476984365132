import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import { resolveEndpoints } from '@repo/backend-utils';
import Config from './common/Config';

// Context
import { DataContextProvider } from './common/contexts/DataContext';
import { DialogContextProvider } from './common/contexts/DialogContext';
import { EventContextProvider } from './common/contexts/EventContext';
import { UserContext } from './common/contexts/UserContext';

// Utils
import createGraphQLApolloClient from './common/utils/createGraphQLApolloClient';

// Pages
import ErrorPage from "./pages/ErrorPage"
import LoginPage from "./pages/LoginPage"
import MainPage from "./pages/MainPage"
import NotFoundPage from "./pages/NotFoundPage"
import SelectTwinPage from './pages/SelectTwinPage';
import UnavailablePage from "./pages/UnavailablePage"


interface PrivateRouteProps {
    children: JSX.Element
}

const ProtectedRoute: React.FC<PrivateRouteProps> = ({ children }) => {

    const { authCreds } = useContext(UserContext);

    if (!authCreds) {
        return <Navigate to="/login" />;
    }

    const endpoints = resolveEndpoints(authCreds.graphqlUrl, Config)

    return <ApolloProvider client={createGraphQLApolloClient(endpoints.httpUrl, endpoints.wsUrl, authCreds.tokens.graphqlToken)}>
        <DataContextProvider>
            <EventContextProvider>
                <DialogContextProvider>
                    {children}
                </DialogContextProvider>
            </EventContextProvider>
        </DataContextProvider>
    </ApolloProvider>
}

const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/select-twin" element={
                    <ProtectedRoute>
                        <SelectTwinPage />
                    </ProtectedRoute>
                }
                />
                <Route path="/" element={
                    <ProtectedRoute>
                        <MainPage />
                    </ProtectedRoute>
                }
                />
                <Route path="/error" element={<ErrorPage />} />
                <Route path="/unavailable" element={<UnavailablePage />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </Router>
    )
}

export default AppRouter