import { useState } from "react"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";

// Types
import { Organisation, TwinEntity } from "@repo/backend-types";

// Data
import { GET_ORGANISATION } from "../common/api/live/gql/getOrganisation";
import { QUERY_TWIN_ENTITY } from "@repo/backend-utils";

// Context
import { useSettingsContext } from "../common/contexts/SettingsContext";
import { useTwinContext } from "../common/contexts/TwinContext";
import { useUserContext } from "../common/contexts/UserContext";

// Components
import { Card, Image } from "@fluentui/react-components";

// TODO - This is temporary and needs removing when we correctly have Twin (Site) preferences stored within the back-end

// Newport Live
import { twinPrefs as stationGymTwinPrefData } from "../common/data/newport-live/station-gym/twinPrefs"

// Movico
import { twinPrefs as movicoTwinPrefData } from "../common/data/movico/mu2-pilot/twinPrefs"

// Howler
import { twinPrefs as howlerDummyHowlerTwinPrefData } from "../common/data/howler/dummy-howler/twinPrefs";
import { twinPrefs as howler30678TwinPrefData } from "../common/data/howler/30678/twinPrefs";
import { twinPrefs as howler33054TwinPrefData } from "../common/data/howler/33054/twinPrefs";
import { twinPrefs as howler28695TwinPrefData } from "../common/data/howler/28695/twinPrefs";
import { twinPrefs as howler32845TwinPrefData } from "../common/data/howler/32845/twinPrefs";

// Total Fitness / Wilmslow
import { twinPrefs as tfWilmslowMainGymTwinPrefData } from "../common/data/total-fitness/wilmslow-main-gym/twinPrefs";
import { twinPrefs as tfWilmslowLadiesGymTwinPrefData } from "../common/data/total-fitness/wilmslow-ladies-gym/twinPrefs";

export type FormInputs = {
    twinId: string,
};

interface Props {
    className?: string
}

const SelectTwin: React.FC<Props> = ({ className }) => {

    const { user, setUser, authCreds, setUserPrefs, setTwinId } = useUserContext();
    const { setTwin } = useTwinContext()
    const { setSettings } = useSettingsContext();
    const navigate = useNavigate()
    const apolloClient = useApolloClient()
    const [twinList, setTwinList] = useState<TwinEntity[]>()

    // clear twin selection first loads
    useEffect(() => {

        setTwinId(null) 
        setTwin(null)

        

    },
    [setTwinId ,setTwin])

    if (authCreds && (twinList === undefined || !user)) {

        apolloClient.query<{ getOrganisation: Organisation }>({
            query: GET_ORGANISATION,
            variables: { name: authCreds.organisation }
        }).then((response) => {
             if (!response.data) {
                 throw new Error('your organisation could not be found');
             } else {

                const nameParts = authCreds.email.split("@")[0].split(".")
                if (!user) setUser({ 
                     email: authCreds.email,
                     firstName: nameParts[0],
                     lastName: nameParts.length > 1 ? nameParts[1] : undefined,
                     organisation: {
                         name: authCreds.organisation
                     }
                 
                 })

                if (twinList === undefined) setTwinList(response.data.getOrganisation.entities ?? [])
             }
             
        })

    }
    

    
    if (!authCreds) navigate("/")

    if (user) {

        // WARNING / TODO: This needs replacing/removing when we correctly have Twin Preferences stored within the back-end
        const setSitePref = (bID: string): boolean => {

            setUserPrefs({
                modelQuery: QUERY_TWIN_ENTITY,
                modelQueryValue: bID,
            })

            try {

                // Newport Live
                if (user.organisation.name === "Newport Live") {
                    switch (bID) {
                        case 'station-gym':
                            setSettings(stationGymTwinPrefData)
                            break;
                        default:
                            throw new Error("Unknown Twin bID");
                    }
                }
    
                // Movico
                if (user.organisation.name === "Movico") {
                    switch (bID) {
                        case 'Mu2-Pilot':
                            setSettings(movicoTwinPrefData)
                            break;
                        default:
                            throw new Error("Unknown Twin bID");
                    }
                }
    
                // Howler
                if (user.organisation.name === "Howler") {
                    switch (bID) {
                        case 'dummy-howler':
                            setSettings(howlerDummyHowlerTwinPrefData)
                            break;
                        case '30678':
                            setSettings(howler30678TwinPrefData)
                            break;
                        case '33054':
                            setSettings(howler33054TwinPrefData)
                            break;
                        case '28695':
                            setSettings(howler28695TwinPrefData)
                            break;
                        case '32845':
                            setSettings(howler32845TwinPrefData)
                            break;
                        default:
                            throw new Error("Unknown Twin bID");
                    }
                }
                    
                // Total Fitness
                if (user.organisation.name === "Total Fitness") {
                    switch (bID) {
                        case 'wilmslow-main-gym':
                            setSettings(tfWilmslowMainGymTwinPrefData)
                            break;
                        case 'wilmslow-ladies-gym':
                            setSettings(tfWilmslowLadiesGymTwinPrefData)
                            break;
                        default:
                            throw new Error("Unknown Twin bID");
                    }
                }
    
                return true

            } catch (error) {
                console.log(`ERROR: `, error)
            }

            return false
        }

        return (
            <div className={className}>
                <div>
                    <div
                        style={{
                            borderRadius: "8px",
                            padding: "4rem 2rem",
                            backgroundColor: "rgb(255,255,255,0.4)",
                            borderWidth: "2px",
                            borderStyle: "solid"
                        }}>

                        <h1 style={{fontSize: '48px', lineHeight: '100%'}}>Select a digital twin</h1>
                        <p style={{ marginTop: '2rem', color: '#707070' }} className="max-w-sm">Select the twin you want to visualise.</p>

                        <div className="mt-16"> 
                            
                            { 
                            // we get away with using entitites here since only the top-level entity is associated to the organisation, so in this particular query entities are twin root entities
                            Array.isArray(twinList) ? twinList.length === 0 ?
                                <div className="bg-red-50 border border-red-800 p-2 mt-2"><p className="text-sm text-center text-red-800">No digital twins available. Please contact support.</p></div>
                                
                                : 

                                twinList.map((site, i) => {
                                    return (
                                        <Card
                                            className={`digital-twin-${i}`}
                                            key={i}
                                            onClick={() => {
                                                setTwinId(site.id)
                                                if (setSitePref(site.bID)) {
                                                    navigate('/')
                                                }
                                            }}
                                        >
                                            <div className="flex mr-10 justify-center items-center">
                                                <div className="p-2" style={{ width: '100px' }}>
                                                    <Image fit="contain" src="./twinlabs-atom-dark-logo.svg" alt="Twinlabs.ai" />
                                                </div>
                                                <div className="flex-1 ml-5">
                                                    <h3 style={{ fontSize: "18px" }}>{site.name}</h3>
                                                </div>
                                            </div>
                                        </Card>
                                    )
                                })

                                : <div/>
                            
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return null
    }

}
      
export default SelectTwin