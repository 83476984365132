import { DateTime } from "luxon";

// Types
import { DateTimeSensorCount } from "../../../@types/DateTimeSensorCount"
import { TSData } from "../../../@types/TSData"

// Utils
import { convertUnixTimestampToDate } from "../convertUnixTimestampToDate"
import { createTSHourlySensorCountObjects } from "../createTSHourlySensorCountObjects"

export const mapTimeseriesSensorDataToDateTime = (data: TSData[], timeZone: string, incremental: boolean = false): DateTimeSensorCount[] => {

    let dateTimeSensorData: DateTimeSensorCount[] = []

    data.map((tsd) => {
        tsd.values.map((tsv) => {

            const unixTimestamp = tsv[0]
            let timeZoneDateTime = DateTime.fromSeconds(unixTimestamp, { zone: timeZone });
            const utcDateTime = DateTime.fromSeconds(unixTimestamp).toUTC();
            const dateString = timeZoneDateTime.toFormat('yyyy-MM-dd');
            const utcISO = utcDateTime.toISO()
            let timeZoneISO = timeZoneDateTime.toISO()
            let hour = timeZoneDateTime.hour
            let count = parseFloat(tsv[1])

            const existingDate = dateTimeSensorData.find(obj => obj.dateString === dateString)

            if (count > 0) {
                if (existingDate) {
                    existingDate.count = existingDate.count + count
                    let existingHour = existingDate.hours.find(obj => obj.hour === hour)
                    
                    if (existingHour) {
                        existingHour.count = existingHour.count ? existingHour.count + count : count
                        existingHour.hourRecordCount = existingHour.hourRecordCount + 1
                    }
                } else {

                    let hours = createTSHourlySensorCountObjects(0)
                    let existingHour = hours.find(obj => obj.hour === hour)
                    if (existingHour) {
                        existingHour.count = count
                    }

                    let entry: DateTimeSensorCount = {
                        utcISO: utcISO,
                        timeZoneISO: timeZoneISO,
                        dateString: dateString,
                        date: convertUnixTimestampToDate(tsv[0]),
                        hours: hours,
                        count: count,
                        activeHours: 0,
                    }

                    dateTimeSensorData.push(entry)
                }
            }

            return null
        })
        return null
    })

    // If we need to incremental total up the hourCount value
    // (e.g. We need to add together countEntity metrics to give us the total headcount for a period of time)
    if (!incremental) {
        dateTimeSensorData.forEach(day => {
            let dayCount = 0
            day.hours.forEach(hour => {
                if (hour.hourRecordCount > 1) {
                    let hourCount = hour.count ? hour.count / hour.hourRecordCount : null
                    hour.count = hourCount && hourCount > 0 ? hourCount : null
                }
                if (hour.count) {
                    dayCount = dayCount + hour.count
                }
            })
        })
    }

    // Active Hours
    dateTimeSensorData.forEach(day => {
        day.activeHours = day.hours.filter(hour => hour.count !== null && hour.count >= 0).length
    })

    return dateTimeSensorData
}