// Types
import { Label } from "../../../../../../@types/Settings/Label"
import { LabelValueType } from "../../../../../../@types/Settings/LabelValueType"

// Utils
import { calcMaxUtilisation } from "../../../../../utils/func-metrics/calcMaxUtilisation"
import { getMaxHeadcount } from "../../../../../utils/func-metrics/getMaxHeadcount"
import { getMaxWindSpeed } from "../../../../../utils/func-metrics/getMaxWindSpeed"

export const LabelSetEventTruckStructureTimeSeriesFull: Label[] = [
    {
        name: 'Type',
        valueType: LabelValueType.ENTITY_PROPERTY,
        value: 'name'
    },
    {
        name: 'Max Utilisation',
        valueName: 'calcMaxUtilisation',
        valueType: LabelValueType.FUNC_METRIC,
        value: calcMaxUtilisation
    },
    {
        name: 'Capacity',
        valueType: LabelValueType.ENTITY_PROPERTY,
        value: 'capacity'
    },
    {
        name: 'Max Headcount',
        valueName: 'getMaxHeadcount',
        valueType: LabelValueType.FUNC_METRIC,
        value: getMaxHeadcount
    },
    {
        name: 'Max Wind Speed',
        valueName: 'getMaxWindSpeed',
        valueType: LabelValueType.FUNC_METRIC,
        value: getMaxWindSpeed
    },
]