import { useEffect, useState } from "react";
import { useSecureModelURL } from "../../utils/modelFilePaths";
import { Entity } from "../../../../@types/Entity";
import { TwinEntity } from "@repo/backend-types";
import { EventSpaceWithModel } from "./EventSpaceWithModel";


interface Props {
    entity: Entity;
    mapDiv: HTMLDivElement;
    loadingList: Map<string, boolean>;
    lineage: TwinEntity[]
}

/**
 * 
 * This component handles the case where a model file is not found, loading a placeholder instead if the model is 
 * not available
 * */ 

export const EventSpace = ({ entity, mapDiv, loadingList, lineage }: Props) => {

    const [chosenModelURL, setChosenModelURL] = useState<string | null>(null)
    
    const prefferedURL = useSecureModelURL(entity.modelFile ?? 'howler-site.glb', false)
    const backupURL = useSecureModelURL('howler-site.glb', false)

    let alreadyLoadedURL: string | null = null

    if (loadingList.get(prefferedURL)) {
        alreadyLoadedURL = prefferedURL
    } else if (loadingList.get(backupURL)) {
        alreadyLoadedURL = backupURL 
    }

    // check if entity.modelFile exists in storage. 
    // N.B. this won't ensure safey if neither entity.modelFile NOR the backup model exists 
    useEffect(() => {
    if (!(chosenModelURL || alreadyLoadedURL)) {
       
            fetch(prefferedURL, {method: "HEAD" }).then((reponse => {
                if (reponse.ok) {
                    setChosenModelURL(prefferedURL)
                } else {
                    setChosenModelURL(backupURL)
                }
            }))
        } 
    },[prefferedURL, backupURL, chosenModelURL, alreadyLoadedURL])
    

    const finalChoiceURL = chosenModelURL ?? alreadyLoadedURL 

    return(
        <group > 
            {finalChoiceURL ?
                // loads models
                <EventSpaceWithModel
                    entity={entity} 
                    modelURL={finalChoiceURL}
                    mapDiv={mapDiv} 
                    loadingList={loadingList}
                    lineage={lineage}
                    />
            : 
                null
            }
        </group>
    )
}
