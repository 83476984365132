/*
 * getLabelValue
 *
*/
import { Data } from "../../@types/Data/Data"
import { DataMode } from "../../@types/DataMode"
import { Entity } from "../../@types/Entity"
import { Label } from "../../@types/Settings/Label"
import { LabelValueType } from "../../@types/Settings/LabelValueType"

export const getLabelValue = async (
    organisation: string,
    entity: Entity,
    label: Label,
    dataSource: Data,
    startDateTime: string | null,
    finishDateTime: string | null,
    timeSeriesURL: string | null
): Promise<any> => {

    if (label.valueType === LabelValueType.ENTITY_PROPERTY && typeof label.value === 'string') {
        // @ts-ignore
        return entity[label.value]
    } else if (label.valueType === LabelValueType.FUNC_METRIC && typeof label.value === 'function') {

        let args: any[]

        switch (label.valueName) {
            // LIVE
            case 'calcUtilisation':
                args = [DataMode.LIVE, dataSource.processed?.countEntity, entity.id, entity.capacity]
                break
            case 'getHeadcount':
                args = [DataMode.LIVE, dataSource.processed?.countEntity, entity.id]
                break
            case 'getWindSpeed':
                args = [DataMode.LIVE, dataSource.processed?.windSpeed, entity.id]
                break
            case 'getWindOrientation':
                args = [DataMode.LIVE, dataSource.processed?.windOrientation, entity.id]
                break
            case 'getTransactionRate':
                args = [DataMode.LIVE, dataSource.processed?.transactionRate, entity.id]
                break
            case 'getCumulativeCount':
                args = [DataMode.LIVE, dataSource.processed?.totalCheckins, entity.id]
                break
            // TIME SERIES
            // AVG
            case 'calcAvgUtilisation':
                args = [DataMode.TIME_SERIES, dataSource.processed?.usage, entity.bID, entity.capacity]
                break
            case 'getAvgHeadcount':
                args = [DataMode.TIME_SERIES, dataSource.processed?.countEntity, entity.bID]
                break
            case 'getAvgTransactionRate':
                args = [DataMode.TIME_SERIES, dataSource.processed?.transactionRate, entity.bID]
                break
            case 'getAvgCumulativeCount':
                args = [DataMode.TIME_SERIES, dataSource.processed?.totalCheckins, entity.bID]
                break
            // MAX
            case 'calcMaxUtilisation':
                if (!startDateTime) {
                    throw new Error("calcMaxUtilisation is missing value for startDateTime");
                }
                if (!finishDateTime) {
                    throw new Error("calcMaxUtilisation is missing value for finishDateTime");
                }
                args = [startDateTime, finishDateTime, organisation, entity.bID, entity.capacity]
                break
            case 'getMaxHeadcount':
                if (!startDateTime) {
                    throw new Error("getMaxHeadcount is missing value for startDateTime");
                }
                if (!finishDateTime) {
                    throw new Error("getMaxHeadcount is missing value for finishDateTime");
                }
                args = [startDateTime, finishDateTime, "1d", organisation, entity.bID]
                break
            case 'getMaxWindSpeed':
                if (!startDateTime) {
                    throw new Error("getMaxWindSpeed is missing value for startDateTime");
                }
                if (!finishDateTime) {
                    throw new Error("getMaxWindSpeed is missing value for finishDateTime");
                }
                args = [startDateTime, finishDateTime, "1d", entity.bID]
                break
            // SUM
            case 'getSumCumulativeCount':

                if (!timeSeriesURL) {
                    throw new Error("getSumCumulativeCount is missing value for timeSeriesURL");
                }

                if (!startDateTime) {
                    throw new Error("getSumCumulativeCount is missing value for startDateTime");
                }

                if (!finishDateTime) {
                    throw new Error("getSumCumulativeCount is missing value for finishDateTime");
                }

                args = [timeSeriesURL, startDateTime, finishDateTime, "10m", organisation, entity.bID]
                break
            default:
                throw new Error(`Unknown function: ${label.valueName}. Check the Twin preferences and ensure the function exists`);
        }
        const result = await label.value(...args);
        return result
    }
    throw new Error('Unknown Label Value Type');
}