// Types
import { Label } from "../../../../../../@types/Settings/Label"
import { LabelValueType } from "../../../../../../@types/Settings/LabelValueType"

// Utils
import { getCumulativeCount } from "../../../../../utils/func-metrics/getCumulativeCount"
import { getTransactionRate } from "../../../../../utils/func-metrics/getTransactionRate"

export const LabelSetEventStationLiveFull: Label[] = [
    {
        name: 'Transaction Rate',
        valueName: 'getTransactionRate',
        valueType: LabelValueType.FUNC_METRIC,
        value: getTransactionRate
    },
    {
        name: 'Cumulative Count',
        valueName: 'getCumulativeCount',
        valueType: LabelValueType.FUNC_METRIC,
        value: getCumulativeCount
    },
]