export function camelCaseRawLoginData(data) {
    return {
        aiKey: data.AIKey,
        aiUrl: data.AIUrl,
        graphqlToken: data.GraphqlToken,
        mapboxToken: data.MapboxToken,
        modelUrlKey: data.ModelUrlKey,
        organisation: data.Organisation,
        userName: data.UserName,
        environment: data.Environment,
        graphqlUrl: data.GraphqlUrl,
        tsdbUrl: data.TsdbUrl
    };
}
