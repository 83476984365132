/* 
* Config is essentially just a wrapper for the env variables
*
*/
const Config = {
    authEndpoint: process.env.REACT_APP_AUTH_ENDPOINT,
    liveGraphQLEndpoint: process.env.REACT_APP_LIVE_GRAPHQL_ENDPOINT,
    liveGraphQLSubscriptions: process.env.REACT_APP_LIVE_GRAPHQL_SUBSCRIPTIONS,
    //timeSeriesGraphQLEndpoint: process.env.REACT_APP_TIMESERIES_GRAPHQL_ENDPOINT,
    initialOnloadDelay: process.env.REACT_APP_INITIAL_ONLOAD_DELAY,
    hotJarSiteId: process.env.REACT_APP_HOTJAR_SITE_ID,
    appProfile: process.env.REACT_APP_PROFILE,
    modelBaseURL: process.env.REACT_APP_MODEL_BASE_URL,
    preflightReport: process.env.REACT_APP_PREFLIGHT_REPORT,
    metricDataWarning: process.env.REACT_APP_METRIC_DATA_WARNING,
    useLocalDgraph: process.env.REACT_APP_USE_LOCAL_DGRAPH
}

export default Config