// Types
import { DataMode } from "../../../../@types/DataMode";
import { DataScope } from "../../../../@types/Settings/DataScope";
import { Environment } from "../../../../@types/Settings/Environment";
import { IndicatorColor } from "../../../../@types/Settings/IndicatorColor";
import { LabelDisplayOn } from "../../../../@types/Settings/LabelDisplayOn";
import { Settings } from "../../../../@types/Settings/Settings";
import { TwinEntityType } from "../../../../@types/TwinEntityType";

// Data / Event Operator Label Configs
import { LabelSetEventOperatorLiveBrief } from "./labelSets/live/LabelSetEventOperatorLiveBrief"
import { LabelSetEventOperatorLiveFull } from "./labelSets/live/LabelSetEventOperatorLiveFull"
import { LabelSetEventOperatorTimeSeriesBrief } from "./labelSets/timeseries/LabelSetEventOperatorTimeSeriesBrief";
import { LabelSetEventOperatorTimeSeriesFull } from "./labelSets/timeseries/LabelSetEventOperatorTimeSeriesFull";
import { LabelSetEventStationLiveBrief } from "./labelSets/live/LabelSetEventStationLiveBrief";
import { LabelSetEventStationLiveFull } from "./labelSets/live/LabelSetEventStationLiveFull";
import { LabelSetEventStationTimeSeriesBrief } from "./labelSets/timeseries/LabelSetEventStationTimeSeriesBrief";
import { LabelSetEventStationTimeSeriesFull } from "./labelSets/timeseries/LabelSetEventStationTimeSeriesFull";

export const twinPrefs: Settings = {
    organisation: 'Howler',
    heroMetrics: [
        {
            metric: 'transactionRate',
            aggregation: 'avg',
            indicatorConfig: {
                selected: [
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.PINK_VIBRANT,
                        op: `value >= 0 && value <= 0.5`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.PINK_MEDIUM,
                        op: `value > 0.5 && value <= 0.6`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_VIOLET,
                        op: `value > 0.6 && value <= 0.7`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_MUTED,
                        op: `value > 0.7 && value <= 0.8`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_BRIGHT,
                        op: `value > 0.8 && value <= 0.9`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_ACCENT,
                        op: `value > 0.9 && value <= 1`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_ACCENT_LIGHT,
                        op: `value > 1 && value <= 1.5`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_ACCENT,
                        op: `value > 1.5 && value <= 2`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_MEDIUM,
                        op: `value > 2`,
                    },
                ],
                nonSelected: [
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.PINK_VIBRANT_NOT_SELECTED,
                        op: `value >= 0 && value <= 0.5`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.PINK_MEDIUM_NOT_SELECTED,
                        op: `value > 0.5 && value <= 0.6`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.BLUE_VIOLET_NOT_SELECTED,
                        op: `value > 0.6 && value <= 0.7`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.BLUE_MUTED_NOT_SELECTED,
                        op: `value > 0.7 && value <= 0.8`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.BLUE_BRIGHT_NOT_SELECTED,
                        op: `value > 0.8 && value <= 0.9`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.BLUE_ACCENT_NOT_SELECTED,
                        op: `value > 0.9 && value <= 1`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.BLUE_ACCENT_LIGHT_NOT_SELECTED,
                        op: `value > 1 && value <= 1.5`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_ACCENT_NOT_SELECTED,
                        op: `value > 1.5 && value <= 2`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_MEDIUM_NOT_SELECTED,
                        op: `value > 2`,
                    },
                ],
            }
        },
        {
            metric: 'totalCheckins',
            aggregation: 'avg',
        },
    ],
    liveDataWarning: false, // Enable/Disable the live data warning symbol in bottom right. This is triggered if live data hasn't been recieved in a specific time period (default 15 mins)
    usageCalc: false, // Enables/Disables usage calculation on front-end. To be used in absence of back-end graphQL usage/utilisation metric
    physicalEntities: ['event', 'station', 'operator'],
    filterEntities: ['event', 'ticketCategory', 'ticketType'],
    liveDataInterval: '15s',
    timeZone: 'Europe/London',
    apolloExplorerURI: 'Howler',
    startLive: true,
    environment: {
        environment: Environment.INDOOR,
        hdrPath: '/images/hdr/indoor.hdr',
    },
    labels: [
        {
            twinEntityType: TwinEntityType.STATION,
            labelSets: [
                {
                    brief: LabelSetEventStationTimeSeriesBrief,
                    full: LabelSetEventStationTimeSeriesFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.TIME_SERIES
                },
                {
                    brief: LabelSetEventStationLiveBrief,
                    full: LabelSetEventStationLiveFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.LIVE
                },
            ]
        },
        {
            twinEntityType: TwinEntityType.OPERATOR,
            labelSets: [
                {
                    brief: LabelSetEventOperatorTimeSeriesBrief,
                    full: LabelSetEventOperatorTimeSeriesFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.TIME_SERIES
                },
                {
                    brief: LabelSetEventOperatorLiveBrief,
                    full: LabelSetEventOperatorLiveFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.LIVE
                },
            ]
        },
    ],
    entities: {
        id: 'entities',
        children: [
            {
                id: '0x28db',
                icon: 'TicketDiagonal20Regular',
                children: [
                    // CK - Staff
                    {
                        id: '0x28dc',
                        content: {
                            chart: {
                                xAxisLabels: [
                                    {
                                        label: 'Manageable',
                                        value: 0,
                                    },
                                    {
                                        label: 'Overwhelmed',
                                        value: 2,
                                    },
                                ]
                            },
                        }
                    },
                    // CK - GA
                    {
                        id: '0x2923',
                        content: {
                            chart: {
                                xAxisLabels: [
                                    {
                                        label: 'Manageable',
                                        value: 0,
                                    },
                                    {
                                        label: 'Overwhelmed',
                                        value: 2,
                                    },
                                ]
                            },
                        }
                    },
                    // CK - F&F Stage Experience (Guest)
                    {
                        id: '0x2987',
                        content: {
                            chart: {
                                xAxisLabels: [
                                    {
                                        label: 'Manageable',
                                        value: 0,
                                    },
                                    {
                                        label: 'Overwhelmed',
                                        value: 2,
                                    },
                                ]
                            },
                        }
                    },
                    // CK - Stage Experience (Paid)
                    {
                        id: '0x2991',
                        content: {
                            chart: {
                                xAxisLabels: [
                                    {
                                        label: 'Manageable',
                                        value: 0,
                                    },
                                    {
                                        label: 'Overwhelmed',
                                        value: 2,
                                    },
                                ]
                            },
                        }
                    },
                    // CK - Mesas VIP Plataforma
                    {
                        id: '0x299b',
                        content: {
                            chart: {
                                xAxisLabels: [
                                    {
                                        label: 'Manageable',
                                        value: 0,
                                    },
                                    {
                                        label: 'Overwhelmed',
                                        value: 2,
                                    },
                                ]
                            },
                        }
                    },
                    // CK - THD
                    {
                        id: '0x29aa',
                        content: {
                            chart: {
                                xAxisLabels: [
                                    {
                                        label: 'Manageable',
                                        value: 0,
                                    },
                                    {
                                        label: 'Overwhelmed',
                                        value: 2,
                                    },
                                ]
                            },
                        }
                    },
                    // CK - Mesas VIP Stage
                    {
                        id: '0x29cf',
                        content: {
                            chart: {
                                xAxisLabels: [
                                    {
                                        label: 'Manageable',
                                        value: 0,
                                    },
                                    {
                                        label: 'Overwhelmed',
                                        value: 2,
                                    },
                                ]
                            },
                        }
                    }
                ]
            },
        ]
    },
    analytics: {
        id: 'analytics',
        introPrompts: [
            {
                title: "Which gate has the lowest check-in rate?",
                prompt: "Which gate has the lowest check-in rate?"
            },
            {
                title: "Who is our fastest operator right now?",
                prompt: "Who is our fastest operator right now?"
            },
        ]
    },
    timeline: {
        id: 'timeline',
        visible: true,
        open: true,
        order: 1,
        interval: '15m',
    },
    calendar: {
        id: 'calendar',
        visible: true,
        icon: '',
        order: 2,
        weekView: {
            dataScope: DataScope.ACTIVE_HOURS,
            hourRange: {
                startTime: '06:00',
                finishTime: '22:00',
            }
        },
        monthView: {
            dataScope: DataScope.ACTIVE_DAYS,
        }
    },
    leftFieldMessages: [
        "Packing the tent",
        "Queuing to use a portable loo",
        "Firing up the disposable BBQ",
        `Trudging through a muddy field`,
    ],
    pollTwin: true,
}