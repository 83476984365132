import { gql } from '@apollo/client';
export const QUERY_TWIN_ENTITY = gql `fragment EntityFragment on TwinEntity {
    id
    bID
    name
    shortName
    description
    tags
    tracked
    mapOrigin {
        latitude
        longitude
      }
    nonFunctional
    aggregateMetrics
    geoType {
      name
      displayName
      description
    }
    type {
      name
    }
    sensors {
      id
      bID
      __typename
      metricType {
        id
        name
        description
        metrics {
          name
          dataType
          description
          unit
        }
      }
    }
    coordinateSystem
    geoType {
      name
    }
    location {
      latitude
      longitude
    }
    altitude
    boundaries {
      polygons {
        coordinates {
          points {
            latitude
            longitude
          }
        }
      }
    }
    rotationX
    rotationY
    rotationZ
    modelFile
    capacity
    maker
    makerID
    definitions
  }
  
  query QueryTwinEntity($filter: TwinEntityFilter) {
  queryTwinEntity(filter: $filter) {
    organisation {
      id
      name
      accounts {
        id
        email
      }
    }
    ...EntityFragment
    children {
      ...EntityFragment
      children {
        ...EntityFragment
        children {
          ...EntityFragment
          children {
            ...EntityFragment
          }
        }
      }
    }
  }
  }`;
export const MUTATION_UPDATE_TWIN_ENTITY_TAGS = gql `
  mutation updateTwinEntity($id: ID!, $tags: [String!]) {
    clearTags: updateTwinEntity(input: {filter: {id: [$id]}, remove: {tags: [""]}}) {
      twinEntity {
        id
      }
    }
    updateTwinEntity(input: { filter: { id: [$id] }, set: { tags: $tags } }) {
      twinEntity {
        id
        tags
      }
    }
  }
`;
export const MUTATION_UPDATE_TWIN_ENTITY_EVENT_DATA = gql `
  mutation updateTwinEntity($id: ID!, $mapOrigin: PointRef!, $rotationY: Float, $altitude: Float) {
    updateTwinEntity(input: { filter: { id: [$id] }, set: { mapOrigin: $mapOrigin, rotationY: $rotationY, altitude: $altitude } }) {
      twinEntity {
        id
        mapOrigin {
          latitude
          longitude
        }
        rotationY
        altitude
      }
    }
  }
`;
