import { useEffect, useState } from "react"

// Context
import { useFilterContext } from "../../common/contexts/FilterContext";

// Types
import { SelectedCellValue } from "../../@types/SelectedCellValue"

// Utils
import { getIndicatorColor } from "../../common/utils/getIndicatorColor"

// Components
import Indicator from "./Indicator";
import { roundToOneDecimalPlace } from "../../common/utils/roundToOneDecimalPlace";



interface Props {
    className?: string
    selectedCellValues: SelectedCellValue[]
}

const CalendarSelectionOverlay = ({ className, selectedCellValues }: Props) => {

    const { heroMetric } = useFilterContext()
    const [indicatorColor, setIndicatorColor] = useState<string | null>(null)
    const [indicatorValue, setIndicatorValue] = useState<string | null>(null)

    useEffect(() => {

            let totalCount = 0
            let numberOfCells = 0

            selectedCellValues.forEach((scv) => {
                if (scv.cellValue) {
                    totalCount = totalCount + scv.cellValue
                }
                numberOfCells = numberOfCells + 1
            })

            const value = totalCount / numberOfCells
            const colors = getIndicatorColor(value, heroMetric?.indicatorConfig, true)

            setIndicatorValue(`${roundToOneDecimalPlace(value).toFixed(1)}`)
            setIndicatorColor(colors.bgColor)

    },
        [
            selectedCellValues,
            heroMetric?.indicatorConfig
        ])

    return (
        <>
        {indicatorValue && indicatorColor ?
        <div className={className}>
            <div className="selection-overlay">
                <div className="selection-overlay-value">
                    <Indicator backgroundColor={parseFloat(indicatorValue) ? indicatorColor : '#FFFFFF'} value={parseFloat(indicatorValue) ? indicatorValue : '-'} />
                </div>
                <div className="selection-overlay-arrow"></div>
            </div>
        </div> : null}
        </>
    )
}

export default CalendarSelectionOverlay