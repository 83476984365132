import React, { useEffect, useMemo, useRef, useState } from "react"
import { DateTime } from "luxon";

// Context
import { useFilterContext } from "../../common/contexts/FilterContext"
import { useSettingsContext } from "../../common/contexts/SettingsContext"
import { useTwinContext } from "../../common/contexts/TwinContext"
import { useUserContext } from "../../common/contexts/UserContext";

// Types
import { DataMode } from "../../@types/DataMode";
import { DateTimeSensorCount } from "../../@types/DateTimeSensorCount"
import { FilterType } from "../../@types/FilterType";
import { IndicatorColors } from "../../@types/Settings/IndicatorColors";
import { SelectionBoxPosition } from "../../@types/SelectionBoxPosition";
import { SelectedCells } from "../../@types/SelectedCells";
import { SelectedCellValue } from "../../@types/SelectedCellValue";
import { Week } from "../../@types/Week"

// Data
import { tsRangeQuery } from "../../common/api/timeseries/tsRangeQuery";

// Utils
import { findEntityById } from "../../common/utils/findEntityById";
import { findMinMaxDates } from "../../common/utils/findMinAndMaxDates"
import { generateCalendarMonth } from "../../common/utils/generateCalendarMonth"
import { getIndicatorColor } from "../../common/utils/getIndicatorColor";
import { mapTimeseriesSensorDataToDateTime } from "../../common/utils/mappers/mapTimeseriesSensorDataToDateTime"
import { convertDateTimeISOToUTC } from "../../common/utils/convertDateTimeToUTC";
import { findObjectByPropertyValue } from "../../common/utils/findObjectByPropertyValue";
import { isSameDate } from "../../common/utils/isSameDate";

// Components
import CalendarMonthChart from "./CalendarMonthChart"
import CalendarSelectionOverlay from "./CalendarSelectionOverlay";
import MonthStepper from "./MonthStepper"

interface Props {
    className?: string
    data?: any[]
}

const CalendarMonthView: React.FC<Props> = ({ className }) => {

    const { analysisReset, filter, heroMetric, setStartDateTime, setFinishDateTime, setLive, setAnalysisReset } = useFilterContext()
    const { settings } = useSettingsContext()
    const { twin } = useTwinContext()
    const { authCreds } = useUserContext()

    const currentDateTime = new Date()
    const timeZone = settings?.timeZone ? settings.timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone
    const [monthViewDateTime, setMonthViewDateTime] = useState<Date>(currentDateTime)
    const [calendarMonth, setCalendarMonth] = useState<Week[]>([])

    let totalCap = 0
    if (twin && twin.totalCapacity) {
        totalCap = twin.totalCapacity
    }

    if (totalCap === 0) {
        throw new Error("Unable to render MonthView calendar. Digital Twin is missing total capacity");
    }

    const digitalTwinEntity = useMemo(() => {
        let entity = twin?.physicalModel.bID;

        const physicalEntityFilters = filter.filter(item => item.type === FilterType.ENTITY);

        if (physicalEntityFilters && physicalEntityFilters.length > 0) {
            entity = physicalEntityFilters[physicalEntityFilters.length - 1].bId;
        }

        return entity;
    }, [twin, filter]);

    const entityCapacity = useMemo(() => {
        let capacity = 0
        if (twin && digitalTwinEntity) {
            let entity = findEntityById(DataMode.TIME_SERIES, [twin.physicalModel], digitalTwinEntity)
            capacity = entity ? entity['capacity'] : 0
        }
        return capacity
    }, [digitalTwinEntity]);

    const updateDateCount = (dateToUpdate: string, count: number) => {
        setCalendarMonth(prevCalendarMonth => {
            return prevCalendarMonth.map(week => {
                const updatedDays = week.days.map(day => {
                    if (day.dateString === dateToUpdate) {
                        // Update the count for the specific date
                        return { ...day, count: count };
                    }
                    return day;
                });
                return { ...week, days: updatedDays };
            });
        });
    };

    useEffect(() => {

        if (authCreds?.tsdbUrl && monthViewDateTime) {

            const fetchCalendarData = async (startDate: string, finishDate: string) => {

                // Get month view range using a combination of startDate and finishDate and the time zone the twin is located in
                let startDateTwinZone = DateTime.fromISO(startDate).setZone(timeZone)
                let finishDateTwinZone = DateTime.fromISO(finishDate).setZone(timeZone).endOf('day')

                const startDateISO = startDateTwinZone.toISO()
                const finishDateISO = finishDateTwinZone.toISO()
                let queryStartDate
                let queryFinishDate

                if (startDateISO && finishDateISO) {
                    queryStartDate = convertDateTimeISOToUTC(startDateISO)
                    queryFinishDate = convertDateTimeISOToUTC(finishDateISO)
                }

                // Convert the twin time zone date to UTC
                if (settings && queryStartDate && queryFinishDate) {

                    let aggregation = heroMetric?.aggregation
                    let metric = heroMetric?.metric


                    // WARNING - CLIENT SPECIFIC CODE: We override the USAGE hero metric here as we need to use countEntity
                    // SOLUTION: Start calculating the usage in the back-end for time series. We can then stop switching to the countMetric.
                    if (heroMetric?.metric === 'usage') {
                        aggregation = settings.heroMetrics[1].aggregation
                        metric = settings.heroMetrics[1].metric
                    }

                    if (!aggregation || !metric) {
                        throw new Error("Hero metric is missing for CalendarWeekView");
                    }

                    let data = await tsRangeQuery(
                        authCreds?.tsdbUrl,
                        queryStartDate,
                        queryFinishDate,
                        aggregation,
                        '24h',
                        settings.organisation,
                        metric,
                        undefined,
                        digitalTwinEntity,
                        undefined,
                        '-24h'
                    )

                    // Map sensor data to DateTime
                    const dateTimeSensorData: DateTimeSensorCount[] = mapTimeseriesSensorDataToDateTime(data, timeZone, metric === 'countEntity' ? true : false)

                    // Hydrate the calendar month with sensor data
                    dateTimeSensorData.forEach(entry => {
                        updateDateCount(entry.dateString, entry.count)
                    });

                    // Refresh SelectedCellValues
                    setRefreshSelectedCellData(true)
                }
            }

            // 1. Generate calendar month
            const calendarMonth = generateCalendarMonth(monthViewDateTime)
            /* @ts-ignore */
            setCalendarMonth(calendarMonth)

            // 2. Use the start/finish date from the calendar month to fetch the sensor data
            let startDate
            let finishDate

            if (Array.isArray(calendarMonth)) {
                startDate = calendarMonth[0].days[0].dateString
                finishDate = calendarMonth[4].days[6].dateString
            }

            if (startDate && finishDate) {
                fetchCalendarData(startDate, finishDate)
            }

        }

    }, [
        authCreds?.tsdbUrl,
        filter,
        heroMetric?.aggregation,
        heroMetric?.metric,
        monthViewDateTime,
        settings,
        timeZone,
        twin?.physicalModel.bID,
    ])

    const setMonth = (currentMonth: Date) => {
        setMonthViewDateTime(currentMonth)
        resetSelection()
    }

    // CALENDAR
    const cellWidthModifier = 43
    const cellHeight = 32
    //const selectionBoxBorderOffset = 2
    const [isDragging, setIsDragging] = useState(false)

    const [currentSelectedValues, setCurrentSelectedValues] = useState<SelectedCellValue[]>([]);
    const [selectedCells, setSelectedCells] = useState<SelectedCells>({ lastModified: DateTime.now().toISO(), selectedValues: [] });
    const [selectedDateTimes, setSelectedDateTimes] = useState<string[]>([])
    const [hideSelectionBox, setHideSelectionBox] = useState<boolean>(false)
    const [refreshSelectedCellData, setRefreshSelectedCellData] = useState(false)

    const [selectionBox, setSelectionBox] = useState({
        startX: 0,
        startY: 0,
        width: 0,
        height: 0,
        borderStyle: 'dashed',
    });

    const [initialMouseY, setInitialMouseY] = useState(0) // Track initial Y position
    const [initialMouseX, setInitialMouseX] = useState(0) // Track initial X position
    const gridRef = useRef<HTMLTableElement>(null)
    const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

    // AnalysisReset: Triggered when the analysis mode is reset
    useEffect(() => {
        if (analysisReset) {
            resetSelection()
            setAnalysisReset(false)
        }
    }, [
        analysisReset,
        setAnalysisReset
    ])

    const findMatchingDay = (data: Week[], dateTimeISO: string) => {

        let cellValue = 0

        // Extract the date part from the input string
        const dateString = dateTimeISO.split("T")[0]

        // Loop through each week in the data
        for (let week of data) {
            // Loop through each day in the current week
            for (let day of week.days) {
                // Check if the date matches
                if (day.dateString === dateString) {

                    // WARNING: We override default behaviour here for usage metric
                    // SOLUTION: Start calculating the usage in the back-end for time series. We can just output the metric value in the cell
                    if (heroMetric?.metric === 'usage') {
                        if (twin && digitalTwinEntity) {
                            let entity = findEntityById(DataMode.TIME_SERIES, [twin.physicalModel], digitalTwinEntity)
                            if (entity) {
                                cellValue = (day.count / entityCapacity) * 100
                            }
                        }
                    }

                    let selectedCellValue: SelectedCellValue = {
                        dateTimeISO: dateTimeISO,
                        count: day.count,
                        cellValue: cellValue
                    }

                    return selectedCellValue

                }
            }
        }
        // If no match is found, return null
        return null;
    }

    // Refresh Selected Cell Data (Updates the value within the indicator and chart values)
    useEffect(() => {
        const selectedCellValues: SelectedCellValue[] = []
        if (selectedDateTimes && selectedDateTimes.length > 0) {
            selectedDateTimes.forEach(sdt => {
                const scv = findMatchingDay(calendarMonth, sdt)
                if (scv) selectedCellValues.push(scv)
            })
        }
        setSelectedCells({ lastModified: DateTime.now().toISO(), selectedValues: selectedCellValues })
        setCurrentSelectedValues(selectedCellValues)
        setRefreshSelectedCellData(false)
    }, [refreshSelectedCellData])


    const handleMouseDown = (value: string, event: React.MouseEvent<HTMLTableCellElement>) => {

        setHideSelectionBox(false)

        if (!gridRef.current) return;
        const gridRect = gridRef.current.getBoundingClientRect();
        const { clientX, clientY } = event
        const relativeX = clientX - gridRect.left
        const relativeY = clientY - gridRect.top

        setIsDragging(true)
        setInitialMouseY(relativeY) // Set initial Y position
        setInitialMouseX(relativeX) // Set initial X position
        setSelectionBox({
            startX: relativeX,
            startY: relativeY,
            width: 0,
            height: 0,
            borderStyle: 'dashed',
        });

        setSelectedCells({ lastModified: DateTime.now().toISO(), selectedValues: [] })
    }

    const handleMouseMove = (event: React.MouseEvent<HTMLTableElement, MouseEvent>) => {

        if (!isDragging || !gridRef.current) return
        const { clientX, clientY } = event
        const sbp = calcSelectionBoxPosition(gridRef, clientX, clientY, calendarMonth.length)

        if (sbp) {

            // Only proceed if the mouse is moving downward and to the right
            if (sbp.relativeY > initialMouseY && sbp.relativeX > initialMouseX) {

                const selectedDateTimes: string[] = []

                for (let row = Math.min(sbp.startRow, sbp.endRow); row < Math.max(sbp.startRow, sbp.endRow); row++) {
                    for (let col = Math.min(sbp.startCol, sbp.endCol); col < Math.max(sbp.startCol, sbp.endCol); col++) {

                        let week = calendarMonth[row - 1]
                        let day = week.days[col - 1]

                        let selectedDateTime = DateTime.fromJSDate(day.date)
                        let selectedDateTimeISO = selectedDateTime.toISO({ suppressMilliseconds: true, includeOffset: false })

                        selectedDateTimes.push(selectedDateTimeISO ? selectedDateTimeISO : '')

                        let dateTimeISO = `${selectedDateTimeISO}`
                        let cellValue = day.count

                        // WARNING: We override default behaviour here for usage metric
                        // SOLUTION: Start calculating the usage in the back-end for time series. We can just output the metric value in the cell
                        if (heroMetric?.metric === 'usage') {
                            if (twin && digitalTwinEntity) {
                                let entity = findEntityById(DataMode.TIME_SERIES, [twin.physicalModel], digitalTwinEntity)
                                if (entity) {
                                    cellValue = (day.count / entityCapacity) * 100
                                }
                            }
                        }

                        let selecteCellValue: SelectedCellValue = {
                            dateTimeISO: dateTimeISO,
                            count: day.count,
                            cellValue: cellValue
                        }
                        setSelectedCellValue(selecteCellValue)
                    }
                }

                setSelectedDateTimes(selectedDateTimes)

                setSelectionBox({
                    startX: sbp.snapStartX,
                    startY: sbp.snapStartY,
                    width: Math.abs(sbp.snapEndX - sbp.snapStartX),
                    height: Math.abs(sbp.snapEndY - sbp.snapStartY),
                    borderStyle: 'dashed',
                })

            }
        }
    }

    const handleMouseClick = (event: any) => {

        if (!gridRef.current) return
        const { clientX, clientY } = event
        const sbp = calcSelectionBoxPosition(gridRef, clientX, clientY, calendarMonth.length)

        if (sbp) {
            const selectedDateTimes: string[] = []

            for (let row = Math.min(sbp.startRow, sbp.endRow); row < Math.max(sbp.startRow, sbp.endRow); row++) {
                for (let col = Math.min(sbp.startCol, sbp.endCol); col < Math.max(sbp.startCol, sbp.endCol); col++) {

                    let week = calendarMonth[row - 1]
                    let day = week.days[col - 1]

                    let selectedDateTime = DateTime.fromJSDate(day.date)
                    let selectedDateTimeISO = selectedDateTime.toISO({ suppressMilliseconds: true, includeOffset: false })

                    selectedDateTimes.push(selectedDateTimeISO ? selectedDateTimeISO : '')

                    let dateTimeISO = `${selectedDateTimeISO}`
                    let cellValue = day.count

                    // WARNING: We override default behaviour here for usage metric
                    // SOLUTION: Start calculating the usage in the back-end for time series. We can then stop switching to the countMetric.
                    if (heroMetric?.metric === 'usage') {
                        cellValue = (day.count / totalCap) * 100
                    }

                    let selecteCellValue: SelectedCellValue = {
                        dateTimeISO: dateTimeISO,
                        count: day.count,
                        cellValue: cellValue,
                    }
                    setSelectedCellValue(selecteCellValue, true)
                }
            }

            setSelectedDateTimes(selectedDateTimes)

            setSelectionBox({
                startX: sbp.snapStartX,
                startY: sbp.snapStartY,
                width: Math.abs(sbp.snapEndX - sbp.snapStartX),
                height: Math.abs(sbp.snapEndY - sbp.snapStartY),
                borderStyle: 'solid',
            })
        }
    }

    const handleMouseUp = () => {
        setIsDragging(false)
        let finalSelectedValues: SelectedCellValue[] = []
        selectedCells.selectedValues.forEach((scv) => {
            if (selectedDateTimes.includes(scv.dateTimeISO)) {
                finalSelectedValues.push(scv)
            }
        })
        setCurrentSelectedValues(finalSelectedValues)
        setSelectionBox({ ...selectionBox, borderStyle: 'solid' })
    }

    const resetSelection = () => {
        setSelectedCells({ lastModified: DateTime.now().toISO(), selectedValues: [] })
        setCurrentSelectedValues([])
        setSelectedDateTimes([])
        setHideSelectionBox(true)
    }

    const setSelectedCellValue = (scv: SelectedCellValue, singleCell: boolean = false) => {
        if (singleCell) {
            setCurrentSelectedValues([scv])
        } else {
            const foundObj = findObjectByPropertyValue(selectedCells.selectedValues, 'dateTimeISO', scv.dateTimeISO)
            if (!foundObj) {
                setSelectedCells({ lastModified: DateTime.now().toISO(), selectedValues: [...selectedCells.selectedValues, scv] })
            }
        }
    }

    const calcSelectionBoxPosition = (
        gridRef: React.RefObject<HTMLTableElement>,
        clientX: number,
        clientY: number,
        numOfWeeks: number,
    ): SelectionBoxPosition | void => {

        if (!gridRef.current) return

        const gridRect = gridRef.current.getBoundingClientRect()
        const relativeX = clientX - gridRect.left
        const relativeY = clientY - gridRect.top

        // Calculate adjusted cell width
        const adjustedCellWidth = (gridRect.width - cellWidthModifier) / 7;

        // Calculate adjusted cell height
        const adjustedCellHeight = (gridRect.height - gridRef.current.querySelector('thead')!.clientHeight) / numOfWeeks;

        // Calculate the cell indices for the start and end of the selection box
        const startCol = Math.floor(initialMouseX / adjustedCellWidth)
        const startRow = Math.floor(initialMouseY / adjustedCellHeight)
        const endCol = Math.ceil(relativeX / adjustedCellWidth)
        const endRow = Math.ceil(relativeY / adjustedCellHeight)

        // Calculate snapping positions
        const snapStartX = startCol * adjustedCellWidth
        const snapStartY = startRow * adjustedCellHeight
        const snapEndX = endCol * adjustedCellWidth
        const snapEndY = endRow * adjustedCellHeight

        return {
            relativeX: relativeX,
            relativeY: relativeY,
            startCol: startCol,
            startRow: startRow,
            endCol: endCol,
            endRow: endRow,
            snapStartX: snapStartX,
            snapStartY: snapStartY,
            snapEndX: snapEndX,
            snapEndY: snapEndY,
        }
    }

    // Detecting that the user has selected cells and then calculating the min/max dates
    useEffect(() => {

        if (!analysisReset && !isDragging && selectedDateTimes && selectedDateTimes.length > 0) {

            const minMaxDates = findMinMaxDates(selectedDateTimes, timeZone)

            if (minMaxDates.minDate && minMaxDates.maxDate) {

                let selectedStartDateTime = DateTime.fromISO(minMaxDates.minDate).setZone(timeZone)
                let selectedFinishDateTime = DateTime.fromISO(minMaxDates.maxDate).setZone(timeZone).endOf('day')
                selectedFinishDateTime = selectedFinishDateTime.endOf('day').set({ millisecond: 0 })

                const startDateTimeISO = selectedStartDateTime.toISO()
                const finishDateTimeISO = selectedFinishDateTime.toISO()

                if (startDateTimeISO && finishDateTimeISO) {
                    setLive(false)
                    setStartDateTime(convertDateTimeISOToUTC(startDateTimeISO))
                    setFinishDateTime(convertDateTimeISOToUTC(finishDateTimeISO))
                }
            }
        }
    }, [
        analysisReset,
        isDragging,
        selectedDateTimes,
        timeZone,
        setLive,
        setStartDateTime,
        setFinishDateTime,
    ])

    return (
        <div className={className}>
            <CalendarMonthChart monthData={calendarMonth} selectedCellValues={currentSelectedValues} entityCapacity={entityCapacity} />
            <MonthStepper monthViewDateTime={monthViewDateTime} setMonth={setMonth} />
            {monthViewDateTime &&
                <div>
                    <div style={{ marginTop: '1rem', position: 'relative' }} onMouseUp={handleMouseUp}>
                        <table id="duration" ref={gridRef} onMouseMove={handleMouseMove}>
                            <thead>
                                <tr>
                                    <th></th>
                                    {weekDays.map((wd, i) => {
                                        return (
                                            <th key={i}
                                                style={{
                                                    verticalAlign: 'middle',
                                                    width: `${cellWidthModifier}px`,
                                                    height: `${cellHeight}px`,
                                                    fontSize: '10px',
                                                    textAlign: 'center',
                                                    color: '#7A7A7A'
                                                }}>
                                                {wd}
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {calendarMonth.map((cm, i) => {
                                    return (
                                        <tr key={i}>
                                            <th
                                                style={{
                                                    verticalAlign: 'middle',
                                                    width: `${cellWidthModifier}px`,
                                                    height: `${cellHeight}px`,
                                                    fontSize: '10px',
                                                    fontWeight: 'normal',
                                                    textAlign: 'center'
                                                }}
                                            >W{cm.weekNo}</th>
                                            {cm.days.map((day, i2) => {

                                                const cellValue = `${day.dateString}T00:00:00`
                                                let selected

                                                if (selectedDateTimes && selectedDateTimes.length === 0) {
                                                    selected = true
                                                } else {
                                                    selected = selectedDateTimes.includes(cellValue)
                                                }

                                                let currentDayStyle = isSameDate(currentDateTime, new Date(day.dateString)) ? { borderBottom: `2px`, borderStyle: 'solid', borderBottomColor: 'rgba(228, 255, 254, 1)' } : {}
                                                let displayValue = day.count

                                                // WARNING: We override default behaviour here for usage metric
                                                // SOLUTION: Start calculating the usage in the back-end for time series. We can just output the metric value in the cell
                                                if (heroMetric?.metric === 'usage') {
                                                    if (twin && digitalTwinEntity) {
                                                        displayValue = (day.count / entityCapacity) * 100
                                                    }
                                                }

                                                let colors: IndicatorColors = getIndicatorColor(displayValue, heroMetric?.indicatorConfig, selected)

                                                return (
                                                    <td
                                                        key={i2}
                                                        data-value={cellValue}
                                                        style={{
                                                            width: `${cellWidthModifier}px`,
                                                            height: `${cellHeight}px`,
                                                            color: colors.textColor,
                                                            backgroundColor: colors.bgColor,
                                                            ...currentDayStyle
                                                        }}
                                                        onClick={(event) => handleMouseClick(event)}
                                                        onMouseDown={(event) => handleMouseDown(cellValue, event)}
                                                    >
                                                        <span className="cell-date">{day.date.getDate()}</span>
                                                        {displayValue > 0 ? `${displayValue.toFixed(1)}` : `-`}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                        {!hideSelectionBox && gridRef && gridRef.current && selectionBox.width !== 0 && selectionBox.height !== 0 && (
                            <div
                                className="selection-box"
                                onClick={() => {
                                    setAnalysisReset(true)
                                }}
                                style={{
                                    position: 'absolute',
                                    left: selectionBox.startX,
                                    top: selectionBox.startY,
                                    width: selectionBox.width,
                                    height: selectionBox.height,
                                    borderStyle: selectionBox.borderStyle,
                                }}
                            >
                                {!isDragging && <CalendarSelectionOverlay selectedCellValues={currentSelectedValues} />}
                            </div>
                        )}
                    </div>
                </div>}
        </div>
    )
}

export default CalendarMonthView