import React from "react"

// Types
import { DataMode } from "../../@types/DataMode"

// Context
import { useDataContext } from "../../common/contexts/DataContext"
import { useFilterContext } from "../../common/contexts/FilterContext"
import { useSettingsContext } from "../../common/contexts/SettingsContext"

// Utils
import { getDataValue } from "../../common/utils/func-metrics/getDataValue"
import { getIndicator } from "../../common/utils/getIndicator"

// Components
import { PeopleError16Regular } from "@fluentui/react-icons"

// CSS
import './EntityIndicator.css';
import { DataValue } from "../../@types/Data/DataValue"

interface Props {
    id: string // This could be an Entity ID or the Entity's bID (Business ID)
    showOverCapacityWarning: boolean
    fontSize?: string | number // must be valid css
    isSelected: boolean
}

const EntityIndicator: React.FC<Props> = ({id, fontSize, showOverCapacityWarning, isSelected }) => {

    const { heroMetric, live } = useFilterContext()
    const { data } = useDataContext()

    let indicator
    let metricData: DataValue[] = []
    metricData = data.processed && heroMetric ? data.processed[heroMetric.metric] : []

    const dataValue = getDataValue(live ? DataMode.LIVE : DataMode.TIME_SERIES, metricData, id)
    indicator = dataValue && getIndicator(dataValue, heroMetric?.indicatorConfig, isSelected)
   
    return (
        <div className="hero-indicator">
            {indicator && indicator.value ?
                <>
                    {showOverCapacityWarning && indicator.value > 100 ? <span className="over-capacity-indicator"><PeopleError16Regular /></span> : null}
                    <span className="hero-indicator-badge" style={{backgroundColor: indicator.colors.bgColor}}></span>
                    <span className="hero-indicator-label" style={{ fontSize: fontSize ?? "12px" }}>{indicator.value.toFixed(1)} {indicator.unit}</span>
                </>
            : null
            }
        </div>
    )
}

export default EntityIndicator