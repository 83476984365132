// Types
import { DataMode } from "../../../../@types/DataMode";
import { DataScope } from "../../../../@types/Settings/DataScope";
import { Environment } from "../../../../@types/Settings/Environment";
import { IndicatorColor } from "../../../../@types/Settings/IndicatorColor";
import { LabelDisplayOn } from "../../../../@types/Settings/LabelDisplayOn";
import { Settings } from "../../../../@types/Settings/Settings";
import { TwinEntityType } from "../../../../@types/TwinEntityType";

// Data / Movico Structure Label Configs
import { LabelSetEventTruckStructureLiveBrief } from "./labelSets/live/LabelSetEventTruckStructureLiveBrief";
import { LabelSetEventTruckStructureLiveFull } from "./labelSets/live/LabelSetEventTruckStructureLiveFull";
import { LabelSetEventTruckStructureTimeSeriesBrief } from "./labelSets/timeseries/LabelSetEventTruckStructureTimeSeriesBrief";
import { LabelSetEventTruckStructureTimeSeriesFull } from "./labelSets/timeseries/LabelSetEventTruckStructureTimeSeriesFull";

// Data / Movico Floor Label Configs
import { LabelSetEventTruckAreaTimeSeriesBrief } from "./labelSets/timeseries/LabelSetEventTruckAreaTimeSeriesBrief";
import { LabelSetEventTruckAreaTimeSeriesFull } from "./labelSets/timeseries/LabelSetEventTruckAreaTimeSeriesFull";
import { LabelSetEventTruckFloorLiveBrief } from "./labelSets/live/LabelSetEventTruckAreaLiveBrief";
import { LabelSetEventTruckFloorLiveFull } from "./labelSets/live/LabelSetEventTruckAreaLiveFull";

export const twinPrefs: Settings = {
    organisation: 'Movico',
    heroMetrics: [
        {
            metric: 'usage',
            aggregation: 'avg',
            indicatorConfig: {
                selected: [
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.INITIAL,
                        op: `value >= 0 && value <= 1`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.CRYSTAL_TINT,
                        op: `value > 1 && value <= 5`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_LIGHT,
                        op: `value > 5 && value <= 10`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_MEDIUM_LIGHT,
                        op: `value > 10 && value <= 15`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_MEDIUM,
                        op: `value > 15 && value <= 20`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_ACCENT,
                        op: `value > 20 && value <= 25`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_ACCENT_LIGHT,
                        op: `value > 25 && value <= 30`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_ACCENT,
                        op: `value > 30 && value <= 40`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_BRIGHT,
                        op: `value > 40 && value <= 50`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_MUTED,
                        op: `value > 50 && value <= 75`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_VIOLET,
                        op: `value > 75 && value <= 100`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.PINK_MEDIUM,
                        op: `value > 100 && value <= 125`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.PINK_VIBRANT,
                        op: `value > 125`,
                    }
                ],
                nonSelected: [
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.INITIAL_NOT_SELECTED,
                        op: `value >= 0 && value <= 1`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.CRYSTAL_TINT_NOT_SELECTED,
                        op: `value > 1 && value <= 5`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_LIGHT_NOT_SELECTED,
                        op: `value > 5 && value <= 10`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_MEDIUM_LIGHT_NOT_SELECTED,
                        op: `value > 10 && value <= 15`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_MEDIUM_NOT_SELECTED,
                        op: `value > 15 && value <= 20`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_ACCENT_NOT_SELECTED,
                        op: `value > 20 && value <= 25`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.BLUE_ACCENT_LIGHT_NOT_SELECTED,
                        op: `value > 25 && value <= 30`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.BLUE_ACCENT_NOT_SELECTED,
                        op: `value > 30 && value <= 40`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.BLUE_BRIGHT_NOT_SELECTED,
                        op: `value > 40 && value <= 50`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.BLUE_MUTED_NOT_SELECTED,
                        op: `value > 50 && value <= 75`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.BLUE_VIOLET_NOT_SELECTED,
                        op: `value > 75 && value <= 100`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.PINK_MEDIUM_NOT_SELECTED,
                        op: `value > 100 && value <= 125`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.PINK_VIBRANT_NOT_SELECTED,
                        op: `value > 125`,
                    }
                ]
            }
        },
        {
            metric: 'countEntity',
            aggregation: 'max',
        },
    ],
    liveDataWarning: true, // Enable/Disable the live data warning symbol in bottom right. This is triggered if live data hasn't been recieved in a specific time period (default 15 mins)
    usageCalc: true, // Enables/Disables usage calculation on front-end. To be used in absence of back-end graphQL usage/utilisation metric
    physicalEntities: ['site', 'structure', 'area'],
    liveDataInterval: '15s',
    timeZone: 'Europe/Paris',
    apolloExplorerURI: 'Twinlabs-Dev02',
    startLive: true,
    environment: {
        environment: Environment.INDOOR,
        hdrPath: '/images/hdr/indoor.hdr',
    },
    labels: [
        {
            twinEntityType: TwinEntityType.STRUCTURE,
            labelSets: [
                {
                    brief: LabelSetEventTruckStructureTimeSeriesBrief,
                    full: LabelSetEventTruckStructureTimeSeriesFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.TIME_SERIES
                },
                {
                    brief: LabelSetEventTruckStructureLiveBrief,
                    full: LabelSetEventTruckStructureLiveFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.LIVE
                },
            ]
        },
        {
            twinEntityType: TwinEntityType.FLOOR,
            labelSets: [
                {
                    brief: LabelSetEventTruckAreaTimeSeriesBrief,
                    full: LabelSetEventTruckAreaTimeSeriesFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.TIME_SERIES
                },
                {
                    brief: LabelSetEventTruckFloorLiveBrief,
                    full: LabelSetEventTruckFloorLiveFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.LIVE
                },
            ]
        },
        {
            twinEntityType: TwinEntityType.AREA,
            labelSets: [
                {
                    brief: LabelSetEventTruckAreaTimeSeriesBrief,
                    full: LabelSetEventTruckAreaTimeSeriesFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.TIME_SERIES
                },
                {
                    brief: LabelSetEventTruckFloorLiveBrief,
                    full: LabelSetEventTruckFloorLiveFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.LIVE
                },
            ]
        },
    ],
    entities: {
        id: 'entities',
        children: [
            {
                id: '0x4e58',
                icon: 'VehicleTruckProfile20Regular',
                header: {
                    showOverCapacityWarning: true,
                },
                children: [
                    {
                        id: '0x4e53',
                        header: {
                            showOverCapacityWarning: true,
                        },
                        content: {
                            chart: {
                                xAxisLabels: [
                                    {
                                        label: 'Moderate',
                                        value: 0,
                                    },
                                    {
                                        label: 'Crowded',
                                        value: 1,
                                    },
                                    {
                                        label: 'Overcrowded',
                                        value: 2,
                                    },
                                ]
                            },
                        }
                    },
                    {
                        id: '0x4e54',
                        header: {
                            showOverCapacityWarning: true,
                        },
                        content: {
                            chart: {
                                xAxisLabels: [
                                    {
                                        label: 'Moderate',
                                        value: 0,
                                    },
                                    {
                                        label: 'Crowded',
                                        value: 1,
                                    },
                                    {
                                        label: 'Overcrowded',
                                        value: 2,
                                    },
                                ]
                            },
                        }
                    },
                    {
                        id: '0x4e55',
                        header: {
                            showOverCapacityWarning: true,
                        },
                        content: {
                            chart: {
                                xAxisLabels: [
                                    {
                                        label: 'Moderate',
                                        value: 0,
                                    },
                                    {
                                        label: 'Crowded',
                                        value: 1,
                                    },
                                    {
                                        label: 'Overcrowded',
                                        value: 2,
                                    },
                                ]
                            },
                        }
                    },
                    {
                        id: '0x4e5a',
                        header: {
                            showOverCapacityWarning: true,
                        },
                        content: {
                            chart: {
                                xAxisLabels: [
                                    {
                                        label: 'Moderate',
                                        value: 0,
                                    },
                                    {
                                        label: 'Crowded',
                                        value: 1,
                                    },
                                    {
                                        label: 'Overcrowded',
                                        value: 2,
                                    },
                                ]
                            },
                        },
                    },
                    {
                        id: '0x4e5c',
                        header: {
                            showOverCapacityWarning: true,
                        },
                        content: {
                            chart: {
                                xAxisLabels: [
                                    {
                                        label: 'Moderate',
                                        value: 0,
                                    },
                                    {
                                        label: 'Crowded',
                                        value: 1,
                                    },
                                    {
                                        label: 'Overcrowded',
                                        value: 2,
                                    },
                                ]
                            },
                        }
                    }
                ]
            },
        ]
    },
    analytics: {
        id: 'analytics',
        introPrompts: []
    },
    timeline: {
        id: 'timeline',
        visible: true,
        open: true,
        order: 1,
        interval: '15m',
        hourRange: {
            startTime: '07:00',
            finishTime: '19:00',
        }
    },
    calendar: {
        id: 'calendar',
        visible: true,
        open: true,
        icon: '',
        order: 2,
        weekView: {
            dataScope: DataScope.ACTIVE_HOURS,
            hourRange: {
                startTime: '07:00',
                finishTime: '19:00',
            }
        },
        monthView: {
            dataScope: DataScope.ACTIVE_DAYS,
        }
    },
    leftFieldMessages: [
        "Fixing a flat tyre",
        "Checking the bike suspension",
        "Shaving my legs to improve aerodynamics",
        `Using The "N+1" Rule to justify my latest bike purchase`,
    ],
    pollTwin: false,
}