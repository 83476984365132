import { BoxGeometry, MeshStandardMaterial, Vector3 } from "three"
import { TwinEntity } from "@repo/backend-types"
import { TwinEntityType } from "../../../../@types/TwinEntityType"
import { LabelMode } from "../../../../@types/LabelMode"
import {determineVisibility, getMenuHovered, getSelectionCriteria} from "../../utils/filterUtils"
import { useFilterContext } from "../../../../common/contexts/FilterContext"
import { useEventContext } from "../../../../common/contexts/EventContext"
import { SelectionOutlines } from "../../outlines/SelectionOutline"
import { Hitbox } from "../../Hitbox"
import { useMemo, useState } from "react"
import { Capacity } from "../../behaviours/Capacity";
import { Label3D } from "../../Label3D"

type Props = {
	idx: number;
    entity: TwinEntity,
    lineage: TwinEntity[],
    mapDiv: HTMLDivElement;
	boxWidth: number;
	parentBoxWidth: number;
	containerDepth: number
	gapRatio: number;
}

const CONTAINER_DEPTH_RATIO = 0.7;

const material = new MeshStandardMaterial({ color: '#dfdfdf', opacity: 0.3 })

export function Operator({entity, lineage, mapDiv, boxWidth, parentBoxWidth, containerDepth, gapRatio, idx } : Props) {


    const { filter } = useFilterContext();
	const { currentHoveredEntity, selectedEntityIds } = useEventContext()
	const [hover, setHover] = useState(false)
	const [pressed, setPressed] = useState(false)

	const position = useMemo(() => new Vector3(boxWidth * (1 + gapRatio) * (idx + 1/2) - parentBoxWidth/2, 0, 0), 
	[boxWidth, gapRatio, idx, parentBoxWidth])

	const parent = lineage[lineage.length - 1]
	const { filtersExist, 
		    areaIsSelected, 
			parentIsSelected,
			siblingIsSelected 
		} = getSelectionCriteria(entity, parent, filter)

	const fullLineageIncludingThisEntity = [...lineage, entity]
	const menuHovered = getMenuHovered(fullLineageIncludingThisEntity, currentHoveredEntity)

	const showSolidOutline = areaIsSelected  || pressed;
	const showDashedOutline = (hover || menuHovered) && !areaIsSelected
	const showBehaviourBoxOutline = !(showSolidOutline || showDashedOutline)

	const behavioursVisible = determineVisibility(entity, {
		filtersExist,
		areaIsSelected,
		parentIsSelected,
		siblingIsSelected,
		isTopLevelArea: false })

	const colorMode = filtersExist && !areaIsSelected && siblingIsSelected ? 'greys' : 'colors';

	const boxDepth = CONTAINER_DEPTH_RATIO * containerDepth

	const {boxGeometry, behaviourBoxGeometry }  = useMemo(() => {
		const boxGeometry = new BoxGeometry(boxWidth, boxDepth, boxDepth)
		boxGeometry.computeBoundingBox()

		const behaviourBoxGeometry = boxGeometry.clone()
		behaviourBoxGeometry.scale(1.1, 1.1, 1.1)
		behaviourBoxGeometry.computeBoundingBox()
		return { boxGeometry, behaviourBoxGeometry }

	},[boxDepth, boxWidth])





	return (
			<mesh
				geometry={boxGeometry}
				material={material}
				position={position}>
				<Hitbox 
					entity={entity}
					geometry={ behaviourBoxGeometry }
					setHover={setHover} 
					setPressed={setPressed}
					mapDiv={mapDiv} 
					lineage={lineage}
				/>
				<Capacity
					entity={entity}
					geometry={behaviourBoxGeometry}
					colorMode={colorMode}
					visible={behavioursVisible}
					showOutline={showBehaviourBoxOutline}
				/>
				<SelectionOutlines
					geometry={behaviourBoxGeometry}
					showDashedOutline={showDashedOutline}
					showSolidOutline={showSolidOutline}/>
				{(hover || menuHovered || areaIsSelected) && 
				<Label3D
					entity={entity}
					entityType={TwinEntityType.OPERATOR}
					labelMode={areaIsSelected ? LabelMode.FULL : LabelMode.BRIEF} 
					boundingBox={boxGeometry.boundingBox!}
					visible={hover || menuHovered || areaIsSelected}
					isSelected={selectedEntityIds.has(entity.id)}
				/>
				}
			</mesh>
	);
   
}