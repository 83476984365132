import React, { useMemo } from "react";
import { DoubleSide, Vector2 } from "three";

// Types
import { Entity } from "./../../../@types/Entity";
import { DataMode } from "../../../@types/DataMode"

// Context
import { useDataContext } from "../../../common/contexts/DataContext";
import { useFilterContext } from "../../../common/contexts/FilterContext";
import { useSettingsContext } from "../../../common/contexts/SettingsContext";

// Utils
import { getDataValue } from "../../../common/utils/func-metrics/getDataValue"
import { getIndicator } from "../../../common/utils/getIndicator";
import { OutlineSolid } from "../outlines/OutlineSolid";

interface Props {
	entity: Entity;
    geometry: any;
    colorMode: 'greys' | 'colors';
    visible: boolean;
    showOutline: boolean;
}

const Capacity = ({ entity, geometry, colorMode, visible, showOutline }: Props) => {

	// Access data-related functions and settings from Data Context.
	const { live, heroMetric } = useFilterContext();
    const { data } = useDataContext();
    const { settings } = useSettingsContext();

    const id = live ? entity.id : entity.bID
    const metric = data.processed && heroMetric ? data.processed[heroMetric.metric] : []
    const dataValue = getDataValue(live ? DataMode.LIVE : DataMode.TIME_SERIES, metric, id)

    const color = useMemo(() => dataValue && getIndicator(
        dataValue, 
        heroMetric?.indicatorConfig,
        colorMode === 'colors' ? true : false).colors.bgColor,
    [dataValue, settings, colorMode])
    
    return(
        <group visible={visible}>
            <OutlineSolid geometry={geometry} lineWidth={0.03} color={color} visible={showOutline}/>
            <mesh
                userData={entity}
                geometry={geometry}
            >
                <meshStandardMaterial
                    color={ color }
                    forceSinglePass
                    transparent
                    opacity={0.4}
                    roughness={0.6}
                    metalness={0.25}
                    side={DoubleSide}
                    normalScale={new Vector2(0.25, 0.25)}
                    depthWrite={false}
                />
            </mesh>
        </group>
    )
}

export { Capacity }