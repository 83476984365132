import { RefObject, Suspense, useEffect, useMemo, useRef, useState } from "react";
import { StatsGl } from '@react-three/drei'
import {  
	LightsAndShadows,
} from "./SceneLighting";
import { useMap } from "react-three-map";
import { DebugThreeGlInfo } from "./helpers/DebugThreeGlInfo";

// Types
import { Twin } from "../../@types/Twin";
import Config from "../../common/Config";
import { PostProcesses } from "./postprocessing/PostProcesses";
import { TwinEntity3D } from "./TwinEntity3D";
import { getCentre, shiftLatLongByMetres } from "./utils/geometry";
import { stringToTwinEntityType } from "../../common/utils/stringToTwinEntityType";
import { TwinEntityType } from "../../@types/TwinEntityType";
import { Point } from "@repo/backend-types";



// Interface props for ThreeScene component
interface Props {
	twin: Twin;
	infoRef: RefObject<HTMLDivElement>;
	onAllModelsLoaded: () => void
	mapOrigin: { latitude: number, longitude: number }
}



/**
	* ThreeScene
	*
	* The ThreeScene component is the main React-Three-Fiber 3D parent container for all procedural 3D assets generated from graph data.
	* The component contains and integrates the required features for InternalSite, ExternalSite, CustomEnvironment ( for environmental lighting )
	* and post-processing effects ( to enhance visual aesthetic ). The ThreeScene can be Extended to be the parent of any twin data context to visualize 3D elements.
 */

const ThreeScene = ({ twin, mapOrigin, infoRef, onAllModelsLoaded }: Props) => {

	
	// poll a dynamically expanded map of models pending loading
	const loadingListRef =  useRef<Map<string, boolean>>(new Map<string, boolean>())
	const [mapInitialized, setMapInitialized] = useState(false)

    useEffect(() => {

        const checkIfAllLoaded = () => {
            let allLoaded = true;

            // if the list is empty this will just leave allloaded as true
            loadingListRef.current.forEach((value, _) => {
                    allLoaded = allLoaded && value // a single false value will cause it to stay false till the end
                })
            
                
            if (allLoaded) {
                onAllModelsLoaded()
                return true
            } else {
                return false
            }
    
        }

		setTimeout(() => {
			const checker = setInterval(() => {
				const allLoaded = checkIfAllLoaded()
				if (allLoaded) {
					clearInterval(checker)
				}
			}, 
			
			100)}, // time between checks
		
		100) // time to allow list to be populated

		return () => {
			loadingListRef.current = new Map<string, boolean>()
		}
		
       
    },[onAllModelsLoaded])

	
	// Access the map and its container element from React-Three-Map.
	const map = useMap();
	const mapDiv: HTMLDivElement | null = useMemo(()=>{
		return map._canvas.parentElement as HTMLDivElement;
	}, [map]) 

	const useIndoorEnv = twin.physicalModel.organisation?.name === "Newport Live";

    useEffect(() => {
			
		if (!mapInitialized) {
			// mapc 
		let mapCenter: Point;
		
		if (stringToTwinEntityType(twin.physicalModel.type.name) === TwinEntityType.SITE) {
		// if it's a site, we need to find the center of the site
			if (twin.physicalModel.boundaries?.polygons && typeof twin.physicalModel.altitude === 'number') {
				const siteCenter = getCentre(twin.physicalModel.boundaries.polygons)
				if (twin.physicalModel.coordinateSystem === "COORD_WGS84") {
				mapCenter = siteCenter
			} else {
				
				const center = shiftLatLongByMetres(mapOrigin, { x: siteCenter.longitude * 100, y: siteCenter.latitude * 100})
				mapCenter = { latitude: center.lat, longitude: center.lng }
				
			}
			} else {
				throw new Error(`site is missing either a boundary or altitude, it's an invalid site. twin: ${twin.physicalModel}`)
			}
		} else {
			mapCenter = mapOrigin
		}
				
       
		// TODO we can do better at calculating bounds from site geometry
		const maxBounds = shiftLatLongByMetres(mapCenter, { x: 500, y: 500})
		const minBounds = shiftLatLongByMetres(mapCenter, { x: -500, y: -500})

		const cameraInitConfig = twin.physicalModel.organisation?.name === "Newport Live" ? {
			zoom: 19.5,
			bearing: 165,
			pitch: 60
		} : {
			zoom: 19,
			bearing: 340,
			pitch: 70
		}

			map.setMaxBounds([minBounds, maxBounds])
			map.setZoom(cameraInitConfig.zoom)
			map.setBearing(cameraInitConfig.bearing)
			map.setPitch(cameraInitConfig.pitch)
			map.setCenter({lat: mapCenter.latitude, lng: mapCenter.longitude})

			setMapInitialized(true)

		}
			}
    ,[map, mapOrigin, twin.physicalModel, mapInitialized])


	return (
				<Suspense>
					{loadingListRef.current &&
						<TwinEntity3D
						entity={twin.physicalModel}
						mapDiv={mapDiv}
						loadingList={loadingListRef.current}
						depth={0} lineage={[]}
						mapOrigin={mapOrigin}
					/>
					}
					<LightsAndShadows indoor={useIndoorEnv}/>
					<PostProcesses/>
					{Config.appProfile ==='DEBUG' &&
					<>
					<StatsGl/>
					<DebugThreeGlInfo infoRef={infoRef}/>
					</>
					}
				</Suspense>
	);
};

export { ThreeScene };