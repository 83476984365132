/* 
* Creates Time Series Apollo Client
* Allows us to send requests to the Time Series GraphQL interface
* 
*/
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Config from '../Config';


const createTimeSeriesApolloClient = (url?: string) => {

    if (!url) {
        throw new Error("Time Series Endpoint not found")
    }

    const httpLink = createHttpLink({
        uri: url,
    });
    
    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
            },
        };
    });
  
    return new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
    });

}

export default createTimeSeriesApolloClient