import { gql } from '@apollo/client';

export const GET_ORGANISATION = gql`
    query GetOrganisationByName($name: String) {
        getOrganisation(name: $name) {
                id
                name
                entities {
                    name
                    bID
                }
        }
    }
`