/* 
* Imports a Apache EChart component dynamically based on the chart type set in the data
*
*/
import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { DebouncedState } from 'use-debounce';

// Context
import { useDataContext } from '../../common/contexts/DataContext'
import { useFilterContext } from '../../common/contexts/FilterContext'
import { useSettingsContext } from '../../common/contexts/SettingsContext'
import { useTwinContext } from '../../common/contexts/TwinContext'

// Types
import { ChartData } from '../../@types/ChartData'
import { DataMode } from '../../@types/DataMode'
import { Entity } from '../../@types/Entity'
import { FilterType } from '../../@types/FilterType'
import { Indicator } from '../../@types/Indicator';
import { IndicatorColor } from '../../@types/Settings/IndicatorColor';
import { onChartItemClick } from '../../@types/onChartItemClick'
import { onChartItemMouseOver } from '../../@types/onChartItemMouseOver'
import { TwinEntityType } from '../../@types/TwinEntityType'

// Utils
import { createFilterPath } from '../../common/utils/createFilterPath'
import { getDataValue } from '../../common/utils/func-metrics/getDataValue'
import { getIndicator } from '../../common/utils/getIndicator'
import { getInitials } from '../../common/utils/getInitials'
import { removeNonNumeric } from '../../common/utils/removeNonNumeric'
import { stringToTwinEntityType } from '../../common/utils/stringToTwinEntityType'



interface Props {
  handleMouseEnter: DebouncedState<(id: string, type: "ASSET" | "ENTITY") => void>
  handleMouseLeave: DebouncedState<() => void>
  componentName: string
  config: { xAxisLabels?: { label: string; value: number; }[]; } | undefined
  entity: Entity
  enableChartHoverSelection: boolean
}

const dynamicImport = (componentName: string) => {
  return import(`./${componentName}`)
    .then((module) => ({ default: module.default }))
    .catch((error) => {
      console.error(`Error loading component "${componentName}":`, error)
      throw error;
    });
};

const DynamicChartRenderer: React.FC<Props> = ({
  handleMouseEnter,
  handleMouseLeave,
  componentName,
  config,
  entity,
  enableChartHoverSelection,
}) => {

  const { twin } = useTwinContext()
  const { data } = useDataContext()
  const { live, filter, heroMetric, removeFilterByType, setFilter } = useFilterContext()
  const { settings } = useSettingsContext()
  const [chartData, setChartData] = useState<ChartData[]>([])


  const selectedItemId = useMemo(() => {
    const result = filter.filter((item) => item.type === FilterType.ENTITY)
    if (result && result.length > 0) {
      return result[result.length - 1].id
    }
  }
  , [filter])

  const setFilterPath = useCallback((pathId: string) => {
    if (twin) {
      // Clear all ENTITY and ASSET filters
      removeFilterByType(FilterType.ENTITY)
      removeFilterByType(FilterType.ASSET)

      // Create the filter path
      const filter = createFilterPath(pathId, twin.physicalModel)

      // Update filter in filterContext
      setFilter(filter)
    }
  }, [twin, removeFilterByType, setFilter])


  useEffect(() => {

    const title = entity.name
    const metric = data.processed && heroMetric ? data.processed[heroMetric.metric] : []

    let cData: ChartData[] = []

    // If the entity has children that are Assets
    if (entity.children && entity.children.length > 0) {


      // Check to see if the selectedAssetId exists within what would be the chart values
      const itemSelected = entity.children.find(item => item.id === selectedItemId);

      entity.children.forEach((e, i) => {

        // We should only be displaying Asset types here
        if (stringToTwinEntityType(e.type.name) === TwinEntityType.ASSET || stringToTwinEntityType(e.type.name) === TwinEntityType.OPERATOR) {

          const id = live ? e.id : e.bID
          const dataValue = getDataValue(live ? DataMode.LIVE : DataMode.TIME_SERIES, metric, id)

          let indicator: Indicator = {
            value: null,
            unit: '',
            colors: {
              bgColor: IndicatorColor.NA,
              textColor: IndicatorColor.BLACK
            }
          }

          if (dataValue && itemSelected && selectedItemId) {
            indicator = getIndicator(dataValue, heroMetric?.indicatorConfig, selectedItemId === id)
          } else if (dataValue) {
            indicator = getIndicator(dataValue, heroMetric?.indicatorConfig, true)
          }

          let label
          if (e.shortName) {
            label = e.shortName
          } else {
            // Attempts to generate a label based on the initials and any numeric values within the title string
            label = `${title && getInitials(title)}${removeNonNumeric(e.bID)}`
          }

          cData.push(
            {
              id: id,
              name: e.name,
              type: FilterType.ASSET,
              parentId: e.parent?.id,
              label: label,
              value: indicator && indicator.value ? indicator.value : 0,
              color: indicator ? indicator.colors.bgColor : IndicatorColor.NA
            })
        }

      })

      // If the entity does NOT have children that are Assets but still requires a chart to be shown based on the data assoicated to it's entity ID
    } else if (entity.children && entity.children.length === 0) {

      const id = live ? entity.id : entity.bID
      const dataValue = getDataValue(live ? DataMode.LIVE : DataMode.TIME_SERIES, metric, id)
      const indicator = dataValue && getIndicator(dataValue, heroMetric?.indicatorConfig, true)

      cData.push(
        {
          id: entity.id,
          name: entity.name,
          type: FilterType.ENTITY,
          parentId: entity.parentId,
          label: entity.name,
          value: indicator && indicator.value ? indicator.value : 0,
          color: indicator ? indicator.colors.bgColor : IndicatorColor.NA
        }
      )

    }

    setChartData(cData)

  }, [
    heroMetric,
    data,
    live,
    filter,
    settings?.organisation,
    selectedItemId,
    entity,
    entity.children,
    entity.capacity,
    entity.id,
    entity.name,
    entity.parentId,
  ])

  interface LazyComponentProps {
    config: { xAxisLabels?: { label: string; value: number; }[]; } | undefined
    data: ChartData[]
    onItemClick: (params: onChartItemClick) => void
    onItemMouseOver: (params: onChartItemMouseOver) => void
    onItemMouseOut: () => void
  }

  const LazyComponent: React.FC<LazyComponentProps> = useMemo(() => lazy(() => dynamicImport(componentName)), [componentName]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <LazyComponent
        config={config}
        data={chartData}
        onItemClick={
          (params: onChartItemClick) => {
            if (params.data) {
              const id = params.data.id;
              setFilterPath(id)
            }
          }
        }
        onItemMouseOver={
          (params: onChartItemMouseOver) => {
            if (enableChartHoverSelection && params.data) {
              const id = params.data.id;
              handleMouseEnter(id, "ASSET")
            }
          }
        }
        onItemMouseOut={
          () => {
            if (enableChartHoverSelection) {
              handleMouseLeave()
            }
          }
        }
      />
    </Suspense>
  );
}

export default DynamicChartRenderer