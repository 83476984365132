import React from "react"
import './LabelHeader.css';

interface Props {
    className?: string
    id: string
    indicator?: JSX.Element
    title: string
    fontSize?: string
    showId?: boolean
}

const LabelHeader: React.FC<Props> = ({className, id, indicator, title, fontSize, showId }) => {
    return (
        <div className={className}>
            <div className="label-header" data-id={id}>
                {title && <div className="label" style={{fontSize: fontSize ? fontSize : '12px'}}>{title}{showId ? <small>({id})</small> : null}</div>}
                {indicator}
            </div>
        </div>
    )
}
export default LabelHeader