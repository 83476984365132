import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useThree } from '@react-three/fiber';
import { Html } from '@react-three/drei';
import { Box3, Camera, Object3D, Vector3 } from 'three';
import { clamp } from 'three/src/math/MathUtils';

// Types
import { DataMode } from '../../@types/DataMode';
import { Entity } from '../../@types/Entity';
import { LabelDisplayOn } from '../../@types/Settings/LabelDisplayOn';
import { LabelMode } from '../../@types/LabelMode';
import { TwinEntityType } from '../../@types/TwinEntityType';

// Contexts
import { DataContext } from '../../common/contexts/DataContext';
import { FilterContext, useFilterContext } from '../../common/contexts/FilterContext';
import { SettingsContext, useSettingsContext } from '../../common/contexts/SettingsContext';
import { UserContext } from '../../common/contexts/UserContext';

// Utils
import { getLabels } from '../../common/utils/getLabels';

// Components
import EntityIndicator from '../EntityIndicator/EntityIndicator';
import Labels from '../Labels/Labels';
import TLCard from '../elements/TLCard/TLCard'



interface Label3DProps {
    entity: Entity;
    boundingBox: Box3;
    entityType: TwinEntityType;
    labelMode: LabelMode;
    visible: boolean;
    isSelected: boolean
}

const MIN_FONTSIZE = 8
const MAX_FONTSIZE = 14
const MAX_MINWIDTH = 201

type LabelStyles = {
    fontSize: number,
    minWidth: number,
}

const updateLabelStyles = (
    styles: LabelStyles,
    objectRef: MutableRefObject<Object3D | null>,
    camera: Camera,
    setStyles: (styles: LabelStyles) => void

) => {
    const labelPostion = new Vector3()
    objectRef.current?.getWorldPosition(labelPostion)
    const fontSize = clamp(Math.round(400 / new Vector3().subVectors(labelPostion, camera.position).length()), MIN_FONTSIZE, MAX_FONTSIZE)
    const minWidth = MAX_MINWIDTH - 20 * (MAX_FONTSIZE - fontSize)
    if ((styles.fontSize !== fontSize) || (styles.minWidth !== minWidth)) {
        setStyles({
            ...styles,
            fontSize,
            minWidth
        })
    }
}

// need to fix labels 
const Label3D = ({ entity, boundingBox, entityType, labelMode, visible, isSelected }: Label3DProps) => {

    const [styles, setStyles] = useState({ fontSize: 14, minWidth: MAX_MINWIDTH })
    const objectRef = useRef<Object3D | null>(null)
    const [eventListenersAdded, setEventListenersAdded] = useState(false)

    const camera = useThree(state => state.camera)

    useEffect(() => {

        if (!eventListenersAdded) {

            window.addEventListener('pointermove', (_) => {
                updateLabelStyles(styles, objectRef, camera, setStyles)
            })

            window.addEventListener('wheel', (_) => {
                updateLabelStyles(styles, objectRef, camera, setStyles)
            })

            setEventListenersAdded(true)
        }

        return () => {
            window.removeEventListener('pointermove', (_) => {
                updateLabelStyles(styles, objectRef, camera, setStyles)

            })
            window.removeEventListener('wheel', (_) => {
                updateLabelStyles(styles, objectRef, camera, setStyles)
            })
        }
    }, [eventListenersAdded, setEventListenersAdded, camera.position, camera, styles])

    // Access data-related functions and settings from Data Context.
    const { settings } = useSettingsContext()
    const { live } = useFilterContext()

    // looks for specific label sets
    const labelSets = entity.content?.labelSets ?? []
    let fullLabels: JSX.Element | null = null

    if (labelMode === LabelMode.FULL) {
        const labels = getLabels(labelSets, entityType, settings?.labels ?? [], LabelMode.FULL, LabelDisplayOn.INFO_CARD, live ? DataMode.LIVE : DataMode.TIME_SERIES)
        fullLabels = <Labels
            entity={entity}
            cssProperties={{ ...styles, marginTop: 5 }}
            labels={labels}
        />
    }

    if (visible) {
        updateLabelStyles(styles, objectRef, camera, setStyles)
    }

    const position = useMemo(() => new Vector3(boundingBox.min.x, boundingBox.min.y, -boundingBox.max.z), [boundingBox])

    return (
        <object3D ref={objectRef} position={position}>
            <UserContext.Consumer>{
                userValue => (
                    <SettingsContext.Consumer>{
                        settingsValue => (
                            <DataContext.Consumer>{
                                // this is a context bridge back-out of r3f. see why here https://github.com/pmndrs/react-three-fiber/issues/262
                                dataValue => (
                                    <FilterContext.Consumer>{
                                        filterValue => (
                                            <Html
                                                key={'lbl'}
                                                center
                                                className='whitespace-nowrap pointer-events-none'

                                            >
                                                <div style={{ display: "block", ...styles }}>
                                                    <TLCard size={"small"}>
                                                        <div>
                                                            <UserContext.Provider value={userValue}>
                                                                <SettingsContext.Provider value={settingsValue}>
                                                                    <DataContext.Provider value={dataValue}>
                                                                        <FilterContext.Provider value={filterValue}>
                                                                            <div className='flex font-bold'>
                                                                                <span style={{ flex: 1 }}>
                                                                                    {entity.name}
                                                                                </span>
                                                                                <div style={{ paddingLeft: '8px' }}>
                                                                                    <EntityIndicator
                                                                                        id={live ? entity.id : entity.bID}
                                                                                        fontSize={styles.fontSize}
                                                                                        showOverCapacityWarning
                                                                                        isSelected={isSelected}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {fullLabels}
                                                                        </FilterContext.Provider>
                                                                    </DataContext.Provider>
                                                                </SettingsContext.Provider>
                                                            </UserContext.Provider>
                                                        </div>
                                                    </TLCard>
                                                </div>
                                            </Html>
                                        )}
                                    </FilterContext.Consumer>
                                )}
                            </DataContext.Consumer>
                        )}
                    </SettingsContext.Consumer>
                )}
            </UserContext.Consumer>
        </object3D>
    )
}

export { Label3D }