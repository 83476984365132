/*
 * getMaxWindSpeed
 * 
*/

// Types
import { tsRangeQuery } from "../../api/timeseries/tsRangeQuery"

export const getMaxWindSpeed = async (timeSeriesURL: string, startDateTime: string, endDateTime: string, step: string = "15m", organisation: string): Promise<string> => {
    
    let value = 'N/A'

    if (startDateTime && endDateTime && step) {

        const maxWindSpeeds = await tsRangeQuery(timeSeriesURL, startDateTime, endDateTime, 'max', step, organisation, 'windSpeed')

        if (maxWindSpeeds && maxWindSpeeds.length > 0) {
            value = `${Math.round(maxWindSpeeds[0].values[0][1])}m/s`
        } else {
            value = 'No value (TS)'
        }

    } else {
        value = 'N/A'
    }

    return value
}