import { useState } from "react"

import { Dismiss16Regular } from "@fluentui/react-icons"
import { ReactComponent as CopilotColorIcon } from "../../common/svg/maria-icon.svg"

// Context
import { useSettingsContext } from "../../common/contexts/SettingsContext"
import { useUserContext } from "../../common/contexts/UserContext"

// Utils
import { capitaliseFirstLetter } from "../../common/utils/capitaliseFirstLetter"

// Components
import Copilot from "../Copilot/Copilot"
import DraggablePanel from "../DraggablePanel/DraggablePanel"
import Subtitle from "../Typography/Subtitle"

// CSS
import './SupportCopilot.css'


interface Props {
  setSupportCopilotVisible: (value: boolean) => void
}

const SupportCopilot = ({ setSupportCopilotVisible }: Props) => {
  
  const { settings } = useSettingsContext()
  const { authCreds, user } = useUserContext() 
  const [isHeaderHovered, setIsHeaderHovered] = useState(false);

  const headerElement = (
    <div className="support-copilot-header" 
        onMouseEnter={() => setIsHeaderHovered(true)}
        onMouseLeave={() => setIsHeaderHovered(false)}>
      <div className="support-copilot-header-title">
        <div className="avatar">
          <CopilotColorIcon />
        </div>
        <Subtitle m="auto">MAR.I.A</Subtitle>
      </div>
      <button className="close-button" onClick={() => setSupportCopilotVisible(false)}>
        <Dismiss16Regular/>
      </button>
    </div>
  );

  return (
    <DraggablePanel headerElement={headerElement} isHeaderHovered={isHeaderHovered}>
      <Copilot
        className="support-copilot"
        apiEndpoint={authCreds?.aiUrl ? authCreds?.aiUrl : ''}
        apiKey={authCreds?.tokens.graphqlToken}
        openingMessage={{
          content: `Hello ${user?.firstName ? `${capitaliseFirstLetter(user?.firstName)},` : ','} my name is MAR.I.A, I'm new and I'm still learning. How can I help you today? Please just ask${settings?.analytics?.introPrompts && settings?.analytics?.introPrompts.length > 0 ? ` or use some of the ideas below to help you get started` : ``}.`,
          response: true,
          prompts: settings?.analytics?.introPrompts,
        }}
        maxHeight={"calc(100vh - 210px)"}
        dynamicHeight
      />
    </DraggablePanel>
  );
};

export default SupportCopilot;