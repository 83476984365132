// TODO: This Settings page is not finished and is currently not accessible within the app. Requires thought/requirements to progress.
import React, { useState } from "react"
import { Color24Regular, DataUsage24Regular, WebAsset24Regular } from "@fluentui/react-icons";

// Types
import { TabPanel } from "../@types/TabPanel";

// Context
//import { useTwinContext } from "../common/contexts/TwinContext";

// Components
// import TLAccordion from "./elements/TLAccordion";
// import TLAccordionItem from "./elements/TLAccordionItem";
import { TLButton } from "@repo/ui";
import TLTabList from "./elements/TLTabList";

interface Props {
    className?: string
    onClose?: () => void
}

const Preferences: React.FC<Props> = ({ className, onClose }) => {

    //const { twin } = useTwinContext();
    const [tabPanel, setTabPanel] = useState<TabPanel>(TabPanel.ASSETS)

    return (
        <div className="w-3/4 mx-auto">
            <header className="flex align-center bg-slate-50 p-4 mb-5">
                <h2 className="text-lg">Settings</h2>
                <TLButton className="ml-auto" onClick={onClose}>Close</TLButton>
            </header>
            <TLTabList
                className="mb-5"
                selectedValue={tabPanel.toString()}
                options={[
                    {
                        activeIcon: <WebAsset24Regular />,
                        value: TabPanel.ASSETS.toString(),
                        text: "Assets"
                    },
                    {
                        activeIcon: <DataUsage24Regular />,
                        value: TabPanel.ANALYTICS.toString(),
                        text: "Panels"
                    },
                    {
                        activeIcon: <Color24Regular />,
                        value: "COLOR_MAPPINGS",
                        text: "Color Mappings"
                    }
                ]}
                setValue={setTabPanel}
            />
            <></>
            {/* <TLAccordion
                className="flex flex-wrap"
                collapsible={true}>
                <>
                    {twin?.model?.map((entity, i) => {
                        return (
                            <TLAccordionItem key={i} className="w-1/2" entity={entity} />
                        )
                    })}
                </>
            </TLAccordion> */}
            <TLButton className="mt-10" onClick={() => { console.log(`SAVE CHANGES`) }}>Save Changes</TLButton>
        </div>
    )
}


export default Preferences