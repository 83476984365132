import { TwinEntity } from "@repo/backend-types";
import { BoxGeometry, Group, Material, Mesh, MeshStandardMaterial, Object3D } from "three";
import { Station } from "./Station";
import { useGLTF } from "@react-three/drei";
import { useEffect, useMemo, useState } from "react";
import { isMesh } from "../../helpers/threeTypeGuards";
import { ASSET_COLOR } from "../../theme";
import { stringToTwinEntityType } from "../../../../common/utils/stringToTwinEntityType";
import { TwinEntityType } from "../../../../@types/TwinEntityType";
import { allocateEntrances } from "../../utils/allocateEntrances";

interface Props {
	entity: TwinEntity,
	modelURL: string,
	lineage: TwinEntity[],
	mapDiv: HTMLDivElement;
	loadingList:Map<string, boolean>;
}

export const STATION_DEPTH = 3;
const OPERATOR_WIDTH = 0.5;
export const GAP_RATIO = 0.2;

const eventSiteMaterial = new MeshStandardMaterial({
	color: ASSET_COLOR,
	opacity:0.9,
	roughness: 0.8,
	metalness: 0.2,
})

const entranceMaterial = new MeshStandardMaterial({
	color: ASSET_COLOR,
	opacity: 0.3,
	transparent: true
})

function sumArray(array: number[]) {
	return array.reduce((partialSum, a) => partialSum + a, 0);
} 

function makeEntrancePlaceHolder(entrance: Object3D) {
	
	const object = new Mesh(new BoxGeometry(1, 3, 1), new MeshStandardMaterial({color: 'red', visible: false}))
		
	entrance.getWorldPosition(object.position)
	entrance.getWorldQuaternion(object.quaternion)

	return object
}


function disposeMaterial(material: Material) {
	material.dispose()

	// dispose textures
	for (const [, value] of Object.entries(material)) {
		if (value && typeof value === 'object' && 'minFilter' in value) {
			value.dispose()
		}
	}
}

function disposeMesh(mesh: Mesh) {

	const material = mesh.material
	const geometry = mesh.geometry

	geometry.dispose()

	if (Array.isArray(material)) {
		material.forEach(individualMaterial => disposeMaterial(individualMaterial))
	} else {
		disposeMaterial(material)
	}


 }

 function disposeScene(scene: Group) {
	scene.traverse((object) => {
		if (isMesh(object)) {
			disposeMesh(object)
		}
	})
 }



export const EventSpaceWithModel = ({ entity, modelURL, lineage, mapDiv, loadingList }: Props) => {

     
	const [toggle, rerender] = useState(false)
	
	const alreadyLoaded = loadingList.get(modelURL)
	if (!alreadyLoaded) {
		loadingList.set(modelURL, false)
	}

	const { nodes, scene } = useGLTF(modelURL)

	useEffect(() => {

		Object.entries(nodes).forEach(entry => {
			const [, object] = entry

			
			if (isMesh(object)) {
				
				const isEntranceMesh = object.parent?.name.includes("entrance") ?? false
			
				// dispose of old material
				const material = object.material
				if (Array.isArray(material)) {
					material.forEach(individualMaterial => disposeMaterial(individualMaterial))
				} else {
					disposeMaterial(material)
				}

				object.geometry.computeBoundingBox()
				object.material = eventSiteMaterial

				if (isEntranceMesh) {
					object.material = entranceMaterial
				} else {
					object.material = eventSiteMaterial
				}
				
			}})


		if (!loadingList.get(modelURL)) rerender(!toggle) // we have to trigger a re-render before the model is seen
		loadingList.set(modelURL, true)

	}, [nodes, loadingList, toggle, modelURL])
	

	const entrancePlaceHolderMapping  = useMemo(() => {
		
		const mapping: { [key: string]: Mesh } = {}

		for (const [key, value] of Object.entries(nodes)) {
			if (key.includes('entrance')) {
				mapping[key] = makeEntrancePlaceHolder(value)
			}
		}


		return mapping
	
	}, [nodes]) 

	
	// cleanup
	useEffect(() => {
		return () => {
			disposeScene(scene)
			Object.values(entrancePlaceHolderMapping).map(container => disposeMesh(container))
		}
	}, [scene, entrancePlaceHolderMapping])

	

	// split into equal-sized groups
	const stations = entity.children?.filter(childEntity => stringToTwinEntityType(childEntity.type.name) === TwinEntityType.STATION) ?? []

	const modelEntranceNames = Object.keys(entrancePlaceHolderMapping)
	const entranceStationMapping = allocateEntrances(stations, modelEntranceNames)
	

	return 	<group >
				<primitive object={scene}/>
				{
				
				Object.entries(entranceStationMapping).map((nameMapping) => {

					const [entranceName, stationGroup] = nameMapping
					
					const operatorNumbers = stationGroup.map(child => child.children?.length ?? 1)
					const sumOfAllOperators = sumArray(operatorNumbers);
					const allWidth = sumOfAllOperators * OPERATOR_WIDTH * (1 + GAP_RATIO)
					
					const object = entrancePlaceHolderMapping[entranceName].translateX(- allWidth / 2)
					return <primitive object={object} > 	
								{
									stationGroup.map(((child, idx) => {
										const operatorNumber = child.children?.length ?? 1
										const boxWidth = operatorNumber * OPERATOR_WIDTH * (1 + GAP_RATIO)
										const allPreviousOperatorNumbers = idx === 0 ? [0] : operatorNumbers.slice(0, idx)
										const sumOfAllPreviousOperators = sumArray(allPreviousOperatorNumbers);
										const stationGap = idx
										const allPreviousWidth = (sumOfAllPreviousOperators * OPERATOR_WIDTH + stationGap) * (1 + GAP_RATIO)
										const position: [number, number, number] = [allPreviousWidth + boxWidth / 2, 0, 0]
									
										return <Station
													key={idx}
													idx={idx}
													entity={child}
													boxWidth={boxWidth}
													position={position}
													boxDepth={STATION_DEPTH}
													lineage={[...lineage, entity]}
													mapDiv={mapDiv}
													gapRatio={GAP_RATIO}
													operatorWidth={OPERATOR_WIDTH}
												/>
										}))
					}
					</primitive>
				})
			}
		</group>
	
};

