import React, { useState } from "react";
import { WebAsset24Filled, WebAsset24Regular } from "@fluentui/react-icons";

// Types
import { TabPanel } from "../@types/TabPanel";

// Components
import Assets from "./Assets/Assets";
import TLCard from "./elements/TLCard/TLCard";
import TLSwitcher from "./elements/TLSwitcher";
import CustomScrollbar from "./CustomScrollbar/CustomScrollbar";

// CSS
import './Frame/Frame.css';

interface Props {
    className?: string;
    liveMode: boolean;
}

interface TabOption {
    inactiveIcon: JSX.Element;
    activeIcon: JSX.Element;
    value: string;
    text: string;
}

const AssetsAndAnalytics: React.FC<Props> = ({ className, liveMode }) => {

    const tabOpts = [
        {
            inactiveIcon: <WebAsset24Regular />,
            activeIcon: <WebAsset24Filled />,
            value: TabPanel.ASSETS.toString(),
            text: "Assets",
        },
    ]

    const [tabPanel, setTabPanel] = useState<TabPanel>(TabPanel.ASSETS);
    const [tabOptions] = useState<TabOption[]>(tabOpts);

    return (
        <div className={className}>
            <div className="panel-header left-panel-header">
                <TLCard className="tab-list" size="small">
                    <TLSwitcher
                        size="small"
                        selectedValue={tabPanel.toString()}
                        options={tabOptions}
                        setValue={setTabPanel}
                    />
                </TLCard>
            </div>
            {tabPanel === TabPanel.ASSETS && <CustomScrollbar className="left-scrollable-container" maxHeight={"calc(100vh - 210px)"}><Assets /></CustomScrollbar>}
        </div>
    );
};

export default AssetsAndAnalytics;
