import React, { useState } from "react"

import { ApolloError } from '@apollo/client';
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

// Types
import { PageMode } from "../@types/PageMode";

// Components
import { Button, MessageBar } from "@fluentui/react-components";
import { TLInput } from '@repo/ui';

export type FormInputs = {
    email: string,
};

const formSchema = {
    email: yup.string().email().required('Please enter your e-mail address'),
}

interface Props {
    className?: string
    setPageMode: (pageMode: PageMode) => void
}

const ResetPassword: React.FC<Props> = ({ className, setPageMode }) => {

    const [serverError, setServerError] = useState<string>("")

    const { register, handleSubmit, formState: { errors } } = useForm<FormInputs>({
        resolver: yupResolver(yup.object(formSchema)),
    });

    const onSubmit: SubmitHandler<FormInputs> = async (formData) => {
        try {
            // TODO: Reset Password functionality required
        } catch (error: unknown) {
            if (error instanceof ApolloError) {
                setServerError(error.message);
            } else {
                setServerError(`${error}`);
            }
        }
    }

    return (
        <div className={className}>
            <div>
                <div
                    style={{
                        borderRadius: "8px",
                        padding: "4rem 2rem",
                        backgroundColor: "rgb(255,255,255,0.4)",
                        borderWidth: "2px",
                        borderStyle: "solid"
                    }}>
                    <h1 style={{fontSize: '48px', lineHeight: '100%'}}>Reset Password</h1>
                    <p style={{ marginTop: '2rem', color: '#707070' }} className="max-w-sm">Forgot your password? Don't worry, just provide your email address. We'll then guide you back into your account.</p>
                    <div className="mt-16">
                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                            <div>
                                <div className="mt-2">
                                    <TLInput
                                        className="mb-4"
                                        id="txtEmail"
                                        label="E-mail"
                                        props={{ type: "email", ...register("email") }}
                                        errorMessage={errors.email?.message}
                                    />
                                </div>
                            </div>
                            <div className="mt-16">
                                <Button style={{
                                    width: "100%",
                                    backgroundColor: '#00BBCC',
                                    color: '#FFFFFF',
                                    fontSize: '18px',
                                    height: '40px',
                                }} type="submit">Reset Password</Button>
                            </div>
                            {serverError ? <MessageBar id={`server-error`} intent="error">{serverError}</MessageBar> : null}
                            <button onClick={() => setPageMode(PageMode.LOGIN)} className="ml-auto">Back to Log in</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword