// Types
import { Label } from "../../../../../../@types/Settings/Label"
import { LabelValueType } from "../../../../../../@types/Settings/LabelValueType"

// Utils
import { getSumCumulativeCount } from "../../../../../utils/func-metrics/getSumCumulativeCount"
import { getTransactionRate } from "../../../../../utils/func-metrics/getTransactionRate"

export const LabelSetEventOperatorTimeSeriesFull: Label[] = [
    {
        name: 'Transaction Rate',
        valueName: 'getAvgTransactionRate',
        valueType: LabelValueType.FUNC_METRIC,
        value: getTransactionRate
    },
    {
        name: 'Cumulative Count',
        valueName: 'getSumCumulativeCount',
        valueType: LabelValueType.FUNC_METRIC,
        value: getSumCumulativeCount
    },
]