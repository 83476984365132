// src/components/elements/TLInput.tsx
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { forwardRef } from "react";
import { makeStyles, shorthands, useId, Input, Label, MessageBar } from "@fluentui/react-components";
import { jsx, jsxs } from "react/jsx-runtime";
var useStyles = makeStyles({
    root: _object_spread({
        display: "flex",
        flexDirection: "column"
    }, shorthands.gap("2px")),
    desc: {
        fontSize: "12px",
        color: "#00000085"
    }
});
var TLInput = forwardRef(function(param, ref) {
    var className = param.className, id = param.id, label = param.label, description = param.description, errorMessage = param.errorMessage, props = param.props;
    var inputId = useId(id);
    var styles = useStyles();
    return /* @__PURE__ */ jsx("div", {
        className: className,
        children: /* @__PURE__ */ jsxs("div", {
            className: styles.root,
            children: [
                /* @__PURE__ */ jsx(Label, {
                    htmlFor: inputId,
                    size: props.size,
                    disabled: props.disabled,
                    children: label
                }),
                description ? /* @__PURE__ */ jsx("p", {
                    className: styles.desc,
                    children: description
                }) : null,
                /* @__PURE__ */ jsx(Input, _object_spread({
                    ref: ref,
                    id: inputId
                }, props)),
                errorMessage ? /* @__PURE__ */ jsx(MessageBar, {
                    id: "".concat(id, "-error"),
                    intent: "error",
                    children: errorMessage
                }) : null
            ]
        })
    });
});
var TLInput_default = TLInput;
// src/components/elements/Buttons/TLButton.tsx
import { Button } from "@fluentui/react-components";
import { jsx as jsx2 } from "react/jsx-runtime";
var TLButton = function(param) {
    var className = param.className, children = param.children, props = param.props, onClick = param.onClick;
    return /* @__PURE__ */ jsx2("div", {
        className: className,
        children: /* @__PURE__ */ jsx2(Button, {
            onClick: onClick,
            children: children
        })
    });
};
var TLButton_default = TLButton;
// src/components/elements/Buttons/PrimaryButton.tsx
import { Button as Button2 } from "@fluentui/react-components";
import { jsx as jsx3 } from "react/jsx-runtime";
var PrimaryButton = function(param) {
    var children = param.children, type = param.type, onClick = param.onClick, props = param.props;
    return /* @__PURE__ */ jsx3(Button2, _object_spread_props(_object_spread({
        style: {
            width: "100%",
            backgroundColor: "#00BBCC",
            color: "#FFFFFF",
            fontSize: "18px",
            height: "40px"
        },
        type: type,
        onClick: onClick
    }, props), {
        children: children
    }));
};
var PrimaryButton_default = PrimaryButton;
export { PrimaryButton_default as PrimaryButton, TLButton_default as TLButton, TLInput_default as TLInput };
