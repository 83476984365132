import { Warning48Filled } from "@fluentui/react-icons"

// Context
import { useDataContext } from "../../common/contexts/DataContext";

import './DataWarningIndicator.css';

const DataWarningIndicator = () => {
    const { warning } = useDataContext()
    return (
        <div className="data-warning-indicator">
            {warning ?
                <Warning48Filled />
                : null}
        </div>
    )
}

export default DataWarningIndicator