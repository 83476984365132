// Types
import { DataMode } from "../../../../@types/DataMode";
import { DataScope } from "../../../../@types/Settings/DataScope";
import { Environment } from "../../../../@types/Settings/Environment";
import { IndicatorColor } from "../../../../@types/Settings/IndicatorColor";
import { LabelDisplayOn } from "../../../../@types/Settings/LabelDisplayOn";
import { Settings } from "../../../../@types/Settings/Settings";
import { TwinEntityType } from "../../../../@types/TwinEntityType";

// Data / Gym Asset Label Configs
import { LabelSetGymAssetTimeSeriesBrief } from "./labelSets/timeseries/LabelSetGymAssetTimeSeriesBrief";
import { LabelSetGymAssetTimeSeriesFull } from "./labelSets/timeseries/LabelSetGymAssetTimeSeriesFull";
import { LabelSetGymAssetLiveBrief } from "./labelSets/live/LabelSetGymAssetLiveBrief";
import { LabelSetGymAssetLiveFull } from "./labelSets/live/LabelSetGymAssetLiveFull";

// Data / Gym Category Label Configs
import { LabelSetGymCategoryTimeSeriesBrief } from "./labelSets/timeseries/LabelSetGymCategoryTimeSeriesBrief";
import { LabelSetGymCategoryTimeSeriesFull } from "./labelSets/timeseries/LabelSetGymCategoryTimeSeriesFull";
import { LabelSetGymCategoryLiveBrief } from "./labelSets/live/LabelSetGymCategoryLiveBrief";
import { LabelSetGymCategoryLiveFull } from "./labelSets/live/LabelSetGymCategoryLiveFull";

// Data / Gym Zone Label Configs
import { LabelSetGymZoneTimeSeriesBrief } from "./labelSets/timeseries/LabelSetGymZoneTimeSeriesBrief";
import { LabelSetGymZoneTimeSeriesFull } from "./labelSets/timeseries/LabelSetGymZoneTimeSeriesFull";
import { LabelSetGymZoneLiveBrief } from "./labelSets/live/LabelSetGymZoneLiveBrief";
import { LabelSetGymZoneLiveFull } from "./labelSets/live/LabelSetGymZoneLiveFull";

export const twinPrefs: Settings = {
    organisation: 'Newport Live',
    heroMetrics: [
        {
            metric: 'usage',
            aggregation: 'avg',
            indicatorConfig: {
                selected: [
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.INITIAL,
                        op: `value >= 0 && value <= 1`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.CRYSTAL_TINT,
                        op: `value > 1 && value <= 5`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_LIGHT,
                        op: `value > 5 && value <= 10`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_MEDIUM_LIGHT,
                        op: `value > 10 && value <= 15`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_MEDIUM,
                        op: `value > 15 && value <= 20`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_ACCENT,
                        op: `value > 20 && value <= 25`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_ACCENT_LIGHT,
                        op: `value > 25 && value <= 30`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_ACCENT,
                        op: `value > 30 && value <= 40`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_BRIGHT,
                        op: `value > 40 && value <= 50`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_MUTED,
                        op: `value > 50 && value <= 75`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_VIOLET,
                        op: `value > 75 && value <= 100`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.PINK_MEDIUM,
                        op: `value > 100 && value <= 125`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.PINK_VIBRANT, 
                        op: `value > 125`,
                    }
                ],
                nonSelected: [
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.INITIAL_NOT_SELECTED,
                        op: `value >= 0 && value <= 1`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.CRYSTAL_TINT_NOT_SELECTED,
                        op: `value > 1 && value <= 5`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_LIGHT_NOT_SELECTED,
                        op: `value > 5 && value <= 10`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_MEDIUM_LIGHT_NOT_SELECTED,
                        op: `value > 10 && value <= 15`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_MEDIUM_NOT_SELECTED,
                        op: `value > 15 && value <= 20`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_ACCENT_NOT_SELECTED,
                        op: `value > 20 && value <= 25`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.BLUE_ACCENT_LIGHT_NOT_SELECTED,
                        op: `value > 25 && value <= 30`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.BLUE_ACCENT_NOT_SELECTED,
                        op: `value > 30 && value <= 40`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.BLUE_BRIGHT_NOT_SELECTED,
                        op: `value > 40 && value <= 50`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.BLUE_MUTED_NOT_SELECTED,
                        op: `value > 50 && value <= 75`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.BLUE_VIOLET_NOT_SELECTED,
                        op: `value > 75 && value <= 100`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.PINK_MEDIUM_NOT_SELECTED,
                        op: `value > 100 && value <= 125`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.PINK_VIBRANT_NOT_SELECTED,
                        op: `value > 125`,
                    }
                ]
            }
        },
        {
            metric: 'countEntity',
            aggregation: 'avg',
        },
    ],
    usageCalc: true, // Enables/Disables usage calculation on front-end. To be used in absence of back-end graphQL usage/utilisation metric
    liveDataWarning: true, // Enable/Disable the live data warning symbol in bottom right. This is triggered if live data hasn't been recieved in a specific time period (default 15 mins)
    physicalEntities: ['site', 'area', 'asset'],
    filterEntities: [],
    liveDataInterval: '15s',
    timeZone: 'Europe/London',
    apolloExplorerURI: 'Twinlabs-Dev-b37eu',
    startLive: true,
    environment: {
        environment: Environment.INDOOR,
        hdrPath: '/images/hdr/indoor.hdr',
    },
    labels: [
        {
            twinEntityType: TwinEntityType.ASSET,
            labelSets: [
                {
                    brief: LabelSetGymAssetTimeSeriesBrief,
                    full: LabelSetGymAssetTimeSeriesFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.TIME_SERIES
                },
                {
                    brief: LabelSetGymAssetLiveBrief,
                    full: LabelSetGymAssetLiveFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.LIVE
                },
            ]
        },
        {
            twinEntityType: TwinEntityType.CATEGORY,
            labelSets: [
                {
                    brief: LabelSetGymCategoryTimeSeriesBrief,
                    full: LabelSetGymCategoryTimeSeriesFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.TIME_SERIES
                },
                {
                    brief: LabelSetGymCategoryLiveBrief,
                    full: LabelSetGymCategoryLiveFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.LIVE
                },
            ]
        },
        // IMPORANT: None of the data in model has ZONE or CATEGORY Digital Twin Entity types
        // Need to change ZONE to AREA in order to show labels within Accordion
        {
            twinEntityType: TwinEntityType.ZONE,
            labelSets: [
                {
                    brief: LabelSetGymZoneTimeSeriesBrief,
                    full: LabelSetGymZoneTimeSeriesFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.TIME_SERIES
                },
                {
                    brief: LabelSetGymZoneLiveBrief,
                    full: LabelSetGymZoneLiveFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.LIVE
                },
            ]
        },
    ],
    entities: {
        id: 'entities',
        children: [
            {
                id: '0x75e5',
                icon: 'Dumbbell20Regular',
            },
        ]
    },
    analytics: {
        id: 'analytics',
        introPrompts: []
    },
    timeline: {
        id: 'timeline',
        visible: true,
        open: true,
        order: 1,
        interval: '15m',
        hourRange: {
            startTime: '06:00',
            finishTime: '22:00',
        }
    },
    calendar: {
        id: 'calendar',
        visible: true,
        open: true,
        icon: '',
        order: 2,
        weekView: {
            dataScope: DataScope.ACTIVE_HOURS,
            hourRange: {
                startTime: '06:00',
                finishTime: '22:00',
            }
        },
        monthView: {
            dataScope: DataScope.ACTIVE_DAYS,
        }
    },
    leftFieldMessages: [
        "Pumping iron",
        "Creating a 3D gym that Arnie's avatar would want to work out in",
        "Pausing for selfie in the gym mirror",
        "Listening to Eye of the Tiger on repeat",
    ],
    pollTwin: false,
}