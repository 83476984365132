/*
 * timeSeriesQuery
 * Time Series queries are similar 
 *
*/
import { TIME_SERIES_RANGE_QUERY } from './gql/timeSeriesRangeQuery';

// Utils
import createTimeseriesApolloClient from '../../utils/createTimeseriesApolloClient';

export const timeSeriesRangeQuery = async (
    timeSeriesURL: string,
    query: string,
    startDateTime: string,
    endDateTime: string,
    step: string = "15m"
) => {
    try {
        const { data } = await createTimeseriesApolloClient(timeSeriesURL).query({
            query: TIME_SERIES_RANGE_QUERY,
            variables: {
                query: query,
                startTime: startDateTime,
                endTime: endDateTime,
                step
            }
        })
        return data.rangeQuery.result
    } catch (error) {
        return null // Return null for now. Might wish to log errors here in future
    }
}